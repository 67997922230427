<template>
    <div>
        <Head />
        <Floating />
        <div class="wrapper pet_certification_card" v-if="PetCertificationCardData">
            <div class="main_bg">
                <nav class="breadcrumb_box">
                    <div class="container">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">飼養寵物認證卡
                            </li>
                        </ol>
                    </div>
                </nav>
                <!-- 生命教育 -->
                <section class="main">
                    <div class="container">
                        <div class="title">
                            <h1>飼養寵物認證卡</h1>
                        </div>
                        <div class="bg">
                            <div class="box">
                                <div class="img_box front">
                                    <img src="/img/pet_certification_card_1.svg" alt="">
                                    <input class="form-control" type="text" id="" style="width: 210px;"
                                        :placeholder="PetCertificationCardData.Number" disabled>
                                </div>
                                <hr>
                                <div class="img_box back">
                                    <img src="/img/pet_certification_card_2.svg" alt="">
                                    <input class="form-control" type="text" id=""
                                        :placeholder="PetCertificationCardData.Name" disabled>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <FooterMenu />
        <Footer />
    </div>
</template>

<script>
import Head from "@/components/Head.vue";
import Floating from "@/components/Floating.vue";
import FooterMenu from "@/components/FooterMenu.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

export default {
    name: 'PetCertificationCard',
    components: {
        Head,
        Floating,
        FooterMenu,
        Footer,
    },
    data() {
        return {
            PetCertificationCardData: null,
        };
    },
    created: function () {
        Vue.axios
            .get(
                this.$store.state.src +
                this.$store.state.subDirectory +
                "/Html/PetCertificationCardData",
                {
                    params: { token: this.$route.params.token },
                }
            )
            .then((response) => {
                this.PetCertificationCardData = response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    },
    mounted: function () { },
    methods: {},
}
</script>