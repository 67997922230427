<template>
    <footer>
        <div v-if="FooterData" v-html="FooterData.Footer_Text">
        </div>
    </footer>
</template>
      
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

export default {
    name: "Footer",
    data() {
        return { FooterData: {}, };
    },
    created: function () {
        Vue.axios
            .get(
                this.$store.state.src + this.$store.state.subDirectory + "/Html/Footer",
                {
                    params: { lang: $cookies.get("aow_site_lang") },
                }
            )
            .then((response) => {
                this.FooterData = response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    },
    mounted: function () { },
    methods: {},
};
</script>