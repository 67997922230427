<template>
  <div class="floating_btn">
    <ul>
      <!-- <li class="service_btn">
        <a href="#">
          <img src="/img/service_btn.svg" alt="" />
          <p>常用服務</p>
        </a>
      </li> -->
      <li class="top_btn" style="display: block;">
        <a href="#">
          <img src="/img/top_btn.svg" alt="" />
          <p>TOP</p>
        </a>
      </li>
    </ul>
  </div>
</template>
    
<script>
export default {
  name: "Floating",
  data() {
    return {};
  },
  mounted: function () {
    // $("html body").scroll(function () {
    //   var N = $("html body").scrollTop();
    //   console.log(N)
    //   if (N > 600) {
    //     $('.floating_btn .top_btn').css('display', 'block');
    //   }
    //   else {
    //     $('.floating_btn .top_btn').css('display', 'none');
    //   };
    // });
    $(".floating_btn .top_btn a").click(function () {
      $('html,body').animate({ scrollTop: 0 }, 1000);
    });
    $(".floating_btn .service_btn a").click(function () {
      var S = $('section.service').position().top + 100;
      $('html,body').animate({ scrollTop: S }, 1000);
    });
  },
  methods: {},
};
</script>