<template>
    <div>
        <Head />
        <Floating />
        <div class="wrapper login_test login_test_list" v-if="ExamAnsweredRecs">
            <div class="main_bg">
                <nav class="breadcrumb_box">
                    <div class="container">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
                            <li class=" breadcrumb-item active" aria-current="page">寵物業資格考試系統</li>
                        </ol>
                    </div>
                </nav>
                <section class="main">
                    <div class="container">
                        <div class="title">
                            <h1>寵物業資格考試系統</h1>
                        </div>
                        <div class="bg">
                            <div class="box">
                                <div class="login">
                                    <h2>歷史成績</h2>
                                    <div class="list">
                                        <!-- 表格 -->
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th class="basis_100">完成測驗時間</th>
                                                    <th class="center">測驗成績</th>
                                                    <th class="basis_100">測驗結果</th>
                                                    <th class="basis_150">資格考試及格證書</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="ExamAnsweredRec in ExamAnsweredRecs"
                                                    :key="ExamAnsweredRec.EARId">
                                                    <td data-th="完成測驗時間" class="basis_100">{{ ExamAnsweredRec.TotalTime }}
                                                    </td>
                                                    <td data-th="測驗成績" class="center">{{ ExamAnsweredRec.Score }}</td>
                                                    <td data-th="測驗結果" class="basis_100" v-if="ExamAnsweredRec.PDF">合格</td>
                                                    <td data-th="測驗結果" class="basis_100" v-if="!ExamAnsweredRec.PDF">不合格
                                                    </td>
                                                    <td data-th="資格考試及格證書" class="basis_150"><a :href="ExamAnsweredRec.PDF"
                                                            target="_blank" v-if="ExamAnsweredRec.PDF">下載</a></td>
                                                </tr>
                                                <tr v-if="ExamAnsweredRecs.length == 0">
                                                    <td class="none">沒有資料</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <paginate :no-li-surround="false" :page-count="pageAll" :page-range="3"
                                        :margin-pages="1" :click-handler="loadList" :prev-text="'«'" :next-text="'»'"
                                        :prev-link-class="'page-link'" :next-link-class="'page-link'"
                                        :container-class="'pagination'" :page-class="'page-item'"
                                        :page-link-class="'page-link'" :active-class="'active'" :hide-prev-next="false"
                                        v-if="pageAll > 1">
                                    </paginate>
                                    <div class="btn_box">
                                        <router-link tag="button" :to="{ name: 'ExamStart' }">返　　回</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <FooterMenu />
        <Footer />
    </div>
</template>

<script>
import Head from "@/components/Head.vue";
import Floating from "@/components/Floating.vue";
import FooterMenu from "@/components/FooterMenu.vue";
import Footer from "@/components/Footer.vue";
import Validate from "@/utils/validate.js";
import VueCookies from "vue-cookies";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

export default {
    name: 'ExamList',
    components: {
        Head,
        Floating,
        FooterMenu,
        Footer,
    },
    data() {
        return {
            ExamAnsweredRecs: null,
            pageAll: 0,
            perPage: 5,
        };
    },
    created: function () {
        this.loadList(1);
    },
    updated: function () { },
    mounted: function () { },
    methods: {
        loadList: function (page) {
            var that = this;
            $.ajax({
                url:
                    this.$store.state.src +
                    this.$store.state.subDirectory +
                    "/Html/ExamList",
                type: "post",
                data: {
                    token: $cookies.get("aow_login_token"),
                    page: page,
                    perPage: that.perPage,
                },
                dataType: "json",
                error: function () { },
                success: function (res) {
                    if (res.status == 1) {
                        that.ExamAnsweredRecs = res.ExamAnsweredRecs;
                        that.perPage = res.perPage;
                        that.pageAll = Math.max(Math.ceil(res.total / that.perPage), 1);
                        document.body.scrollTop = 0;
                    }
                },
            });
        },
    },
}
</script>