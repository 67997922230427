<template>
    <div>
        <Head />
        <Floating />
        <div class="wrapper activity_main" v-if="ActivityData">
            <div class="main_bg">
                <nav class="breadcrumb_box">
                    <div class="container">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
                            <li class=" breadcrumb-item active" aria-current="page">{{
                                ActivityData.OfficalWebActivity.Name
                                }}</li>
                        </ol>
                    </div>
                </nav>
                <section class="main">
                    <div class="container">
                        <div class="title">
                            <h1>{{ ActivityData.OfficalWebActivity.Name }}</h1>
                        </div>
                        <div class="link_box">
                            <ul class="link">
                                <li>
                                    <a :href="ActivityData.ShareUrl_Facebook" target="_blank"
                                        title="分享至Facebook, 另開新視窗"><img src="/img/icon_fb.svg" alt=""></a>
                                </li>
                                <li>
                                    <a :href="ActivityData.ShareUrl_Line" target="_blank" title="分享至Line, 另開新視窗"><img
                                            src="/img/icon_line.svg" alt=""></a>
                                </li>
                                <!-- <li>
                                    <a href=""><img src="./img/icon_share.svg" alt=""></a>
                                </li> -->
                            </ul>
                        </div>
                        <div class="box">
                            <div class="cover_img">
                                <div class="img_box">
                                    <img src="/img/img_1.png" alt="" v-if="!ActivityData.OfficalWebActivity.CoverImage">
                                    <img :src="ActivityData.SiteUrl + ActivityData.OfficalWebActivity.CoverImage" alt=""
                                        v-if="ActivityData.OfficalWebActivity.CoverImage">
                                </div>
                            </div>
                            <div class="information">
                                <ul class="date">
                                    <li>
                                        <p class="title"><img src="/img/activity_main_icon1.svg" alt="">活動地點：</p>
                                        <p>{{ ActivityData.OfficalWebActivity.Location }}</p>
                                    </li>
                                    <li>
                                        <p class="title"><img src="/img/activity_main_icon2.svg" alt="">活動日期：</p>
                                        <p>{{ ActivityData.OfficalWebActivity.ActDateS | timeString("YYYY-MM-DD") }}</p>
                                    </li>
                                    <li>
                                        <p class="title"><img src="/img/activity_main_icon3.svg" alt="">活動時間：</p>
                                        <p>{{ ActivityData.OfficalWebActivity.ActDateS | timeString("HH:mm") }} - {{
                                            ActivityData.OfficalWebActivity.ActDateE | timeString("HH:mm") }}</p>
                                    </li>
                                    <li v-if="false">
                                        <p class="title"><img src="/img/activity_main_icon4.svg" alt="">報名人數：</p>
                                        <p>{{ ActivityData.OfficalWebActivity.BookingLimit }}人</p>
                                    </li>
                                </ul>
                                <div class="btn_box" v-if="!ActivityData.OfficalWebActivity.Booking">
                                    <button disabled>暫不開放報名</button>
                                </div>
                                <div class="btn_box"
                                    v-if="ActivityData.OfficalWebActivity.Booking && !ActivityData.CanBooking">
                                    <button disabled>報名額滿</button>
                                </div>
                                <div class="btn_box"
                                    v-if="ActivityData.OfficalWebActivity.Booking && ActivityData.CanBooking">
                                    <button>我要報名</button>
                                </div>
                            </div>
                        </div>
                        <div class="text">
                            <h2>活動內容</h2>
                            <div v-html="ActivityData.OfficalWebActivity.Content">
                            </div>
                        </div>
                        <div class="apply_box"
                            v-if="ActivityData.OfficalWebActivity.Booking && ActivityData.CanBooking">
                            <h2>我要報名</h2>
                            <div class="box">
                                <div class="form-group">
                                    <label for="">姓名<span>*</span></label>
                                    <input type="" class="form-control" id="" aria-describedby="" placeholder="請輸入真實姓名"
                                        v-model="Name">
                                </div>
                                <div class="form-group">
                                    <label for="">年齡</label>
                                    <input type="number" class="form-control" id="" aria-describedby=""
                                        placeholder="請輸入年齡" v-model="Age">
                                </div>
                                <div class="form-group">
                                    <label for="">連絡電話<span>*</span></label>
                                    <input type="" class="form-control" id="" aria-describedby="" placeholder="請輸入連絡電話"
                                        v-model="Phone">
                                </div>
                                <div class="form-group">
                                    <label for="">電子信箱<span>*</span></label>
                                    <input type="" class="form-control" id="" aria-describedby="" placeholder="請輸入電子信箱"
                                        v-model="Email">
                                </div>
                                <!-- <div class="form-group">
                                    <label for="">驗證碼<span>*</span></label>
                                    <input class="form-control" placeholder="驗證碼" v-model="identifyCode_input" />
                                </div>
                                <div class="form-group code_box">
                                    <div class="code">
                                        <Sidentify :identifyCode="identifyCode"></Sidentify>
                                        <a href="javascript:void(0)" @click="refreshCode()"><img src="/img/icon-rotate.svg"
                                                alt="" /></a>
                                        <a href="javascript:void(0)" @click="playCode()"><img src="/img/icon-voice.svg"
                                                alt="" /></a>
                                    </div>
                                </div> -->
                                <div class="btn_box">
                                    <!-- <button :disabled="identifyCode != identifyCode_input" :style="identifyCode != identifyCode_input ? 'cursor: no-drop;' : ''
                                        " @click="SendBooking()">確認送出</button> -->
                                    <button @click="SendBooking()">確認送出</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <FooterMenu />
        <Footer />
    </div>
</template>

<script>
import Head from "@/components/Head.vue";
import Floating from "@/components/Floating.vue";
import FooterMenu from "@/components/FooterMenu.vue";
import Footer from "@/components/Footer.vue";
import Validate from "@/utils/validate.js";
import Sidentify from "@/components/Identify";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

export default {
    name: 'Activity',
    components: {
        Head,
        Floating,
        FooterMenu,
        Footer,
        Sidentify,
    },
    data() {
        return {
            ActivityData: null,
            // identifyCode: "0511",
            // identifyCode_input: "",
            // identifyCodes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
            // identifyCode_sound: ["0.mp3", "5.mp3", "1.mp3", "1.mp3"],
            // num: 0,
            Name: '',
            Age: '',
            Phone: '',
            Email: '',
        };
    },
    created: function () {
        Vue.axios
            .get(
                this.$store.state.src + this.$store.state.subDirectory + "/Html/Activity",
                {
                    params: { AId: this.$route.params.AId },
                }
            )
            .then((response) => {
                this.ActivityData = response.data;
            })
            .catch((error) => {
                console.log(error);
            });
        // this.refreshCode();
    },
    updated: function () {
        $(".activity_main .information .btn_box button").unbind();
        $(".activity_main .information .btn_box button").click(function () {
            var A = $('.activity_main .apply_box').position().top + 100;
            $('html,body').animate({ scrollTop: A }, 1000);
        });
    },
    mounted: function () { },
    methods: {
        async getRecaptchaToken() {
            await this.$recaptchaLoaded();
            const token = await this.$recaptcha('login');
            return token;
        },
        // randomNum(min, max) {
        //     return Math.floor(Math.random() * (max - min) + min);
        // },
        // refreshCode() {
        //     this.identifyCode = "";
        //     this.makeCode(this.identifyCodes, 4);
        // },
        // makeCode(o, l) {
        //     for (let i = 0; i < l; i++) {
        //         var code =
        //             this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
        //         this.identifyCode += code;
        //         this.identifyCode_sound[i] = code + ".mp3";
        //     }
        // },
        // playCode() {
        //     var sound_path = "/voice/numvoice/zh";
        //     var audio = new Audio(
        //         sound_path + "/" + this.identifyCode_sound[this.num]
        //     );
        //     audio.play();
        //     let that = this;
        //     audio.addEventListener(
        //         "ended",
        //         function () {
        //             that.num++;
        //             if (that.num < 4) that.playCode();
        //             if (that.num >= 4) that.num = 0;
        //         },
        //         false
        //     );
        // },
        async SendBooking() {
            if (this.Name == "") {
                Swal.fire({
                    title: "錯誤",
                    text: "請輸入姓名",
                    icon: "error",
                    allowOutsideClick: false,
                    confirmButtonText: "確定",
                    heightAuto: false,
                });
                return;
            }
            if (this.Age == "") {
                Swal.fire({
                    title: "錯誤",
                    text: "請輸入年齡",
                    icon: "error",
                    allowOutsideClick: false,
                    confirmButtonText: "確定",
                    heightAuto: false,
                });
                return;
            }
            if (this.Phone == "") {
                Swal.fire({
                    title: "錯誤",
                    text: "請輸入連絡電話",
                    icon: "error",
                    allowOutsideClick: false,
                    confirmButtonText: "確定",
                    heightAuto: false,
                });
                return;
            } else if (!Validate.reg_phone(this.Phone)) {
                Swal.fire({
                    title: "錯誤",
                    text: "連絡電話格式錯誤",
                    icon: "error",
                    allowOutsideClick: false,
                    confirmButtonText: "確定",
                    heightAuto: false,
                });
                return;
            }
            if (this.Email == "") {
                Swal.fire({
                    title: "錯誤",
                    text: "請輸入電子信箱",
                    icon: "error",
                    allowOutsideClick: false,
                    confirmButtonText: "確定",
                    heightAuto: false,
                });
                return;
            } else if (!Validate.reg_email(this.Email)) {
                Swal.fire({
                    title: "錯誤",
                    text: "電子信箱格式錯誤",
                    icon: "error",
                    allowOutsideClick: false,
                    confirmButtonText: "確定",
                    heightAuto: false,
                });
                return;
            }
            // 我不是機器人
            let recaptchaToken = await this.getRecaptchaToken();
            // 顯示請稍候
            Swal.fire({
                title: "請稍候...",
                allowOutsideClick: false,
                showConfirmButton: false,
                heightAuto: false,
                willOpen: function () {
                    Swal.showLoading();
                },
            });
            let that = this;
            $.ajax({
                url:
                    that.$store.state.src +
                    that.$store.state.subDirectory +
                    "/Html/ActivityBooking",
                type: "post",
                data: {
                    AId: that.ActivityData.OfficalWebActivity.AId,
                    Name: that.Name,
                    Age: that.Age,
                    Phone: that.Phone,
                    Email: that.Email,
                    token: recaptchaToken,
                },
                dataType: "json",
                error: function () { },
                success: function (res) {
                    // 判斷結果
                    if (res.ok) {
                        that.Name = '';
                        that.Age = '';
                        that.Phone = '';
                        that.Email = '';
                        //that.identifyCode_input = '';
                        //that.refreshCode();
                        Swal.fire({
                            title: "報名成功",
                            icon: "success",
                            allowOutsideClick: false,
                            confirmButtonText: "確定",
                            heightAuto: false,
                        });
                    } else if (res.error) {
                        Swal.fire({
                            title: "錯誤",
                            text: res.error,
                            icon: "error",
                            allowOutsideClick: false,
                            confirmButtonText: "確定",
                            heightAuto: false,
                        });
                    }
                },
            });
        }
    },
}
</script>