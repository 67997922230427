<template>
    <div>
        <Head />
        <Floating />
        <div class="wrapper list list_1 activity_list" v-if="ActivityData">
            <div class="main_bg">
                <nav class="breadcrumb_box">
                    <div class="container">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">活動訊息
                            </li>
                        </ol>
                    </div>
                </nav>
                <!-- 活動訊息 活動列表 -->
                <section class="news">
                    <div class="container">
                        <div class="title">
                            <h1>活動訊息</h1>
                        </div>
                        <div class="bg">
                            <div class="search_box">
                                <div class="form-group ">
                                    <select class="form-control" v-model="Location">
                                        <option v-for="(_Location, index) in ActivityData.Locations" :key="index" :value="index == 0 ? '' : _Location">{{ _Location }}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label for="">日期</label>
                                    <input type="date" class="form-control" aria-describedby="" placeholder="" v-model="StartTime">
                                    <label for="">至</label>
                                    <input type="date" class="form-control" aria-describedby="" placeholder="" v-model="EndTime">
                                </div>
                                <button class="" @click="loadList(1)">搜尋</button>
                            </div>
                            <ul class="list show">
                                <li v-for="OfficalWebActivity in OfficalWebActivitys" :key="OfficalWebActivitys.AId">
                                    <a href="javascript:void(0)" @click="gotoActivityByAId(OfficalWebActivity.AId)">
                                        <div class="img_box">
                                            <div class="img">
                                                <img src="/img/img_3.png" alt="" v-if="!OfficalWebActivity.CoverImage">
                                                <img :src="OfficalWebActivity.CoverImage" alt=""
                                                    v-if="OfficalWebActivity.CoverImage">
                                            </div>
                                        </div>
                                        <div class="text">
                                            <div>
                                                <p><img src="/img/activity_main_icon1.svg" alt="">{{
                                                    OfficalWebActivity.Location }}</p>
                                                <p class=""><img src="/img/activity_main_icon2.svg" alt="">{{
                                                    OfficalWebActivity.ActDateS | timeString("YYYY-MM-DD") }}</p>
                                            </div>
                                            <p>{{ OfficalWebActivity.Name }}</p>
                                            <p>{{ OfficalWebActivity.Content }}</p>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <paginate :no-li-surround="false" :page-count="pageAll" :page-range="3" :margin-pages="1"
                            :click-handler="loadList" :prev-text="'«'" :next-text="'»'" :prev-link-class="'page-link'"
                            :next-link-class="'page-link'" :container-class="'pagination'" :page-class="'page-item'"
                            :page-link-class="'page-link'" :active-class="'active'" :hide-prev-next="false"
                            v-if="pageAll > 1">
                        </paginate>
                    </div>
                </section>
            </div>
        </div>
        <FooterMenu />
        <Footer />
    </div>
</template>

<script>
import Head from "@/components/Head.vue";
import Floating from "@/components/Floating.vue";
import FooterMenu from "@/components/FooterMenu.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

import Paginate from "vuejs-paginate";

Vue.component("paginate", Paginate);

export default {
    name: 'Activity',
    components: {
        Head,
        Floating,
        FooterMenu,
        Footer,
    },
    data() {
        return {
            ActivityData: null,
            OfficalWebActivitys: null,
            pageAll: 0,
            perPage: 5,
            Location: '',
            StartTime: '',
            EndTime: '',
        };
    },
    created: function () {
        Vue.axios
            .get(
                this.$store.state.src +
                this.$store.state.subDirectory +
                "/Html/ActivityData",
                {
                    params: {},
                }
            )
            .then((response) => {
                this.ActivityData = response.data;
                this.loadList(1);
            })
            .catch((error) => {
                console.log(error);
            });
    },
    mounted: function () { },
    methods: {
        loadList: function (page) {
            var that = this;
            $.ajax({
                url:
                    this.$store.state.src +
                    this.$store.state.subDirectory +
                    "/Html/ActivityList",
                type: "post",
                data: {
                    page: page,
                    perPage: that.perPage,
                    location: that.Location,
                    startTime: that.StartTime,
                    endTime: that.EndTime,
                },
                dataType: "json",
                error: function () { },
                success: function (res) {
                    if (res.status == 1) {
                        that.OfficalWebActivitys = res.OfficalWebActivitys;
                        that.perPage = res.perPage;
                        that.pageAll = Math.max(Math.ceil(res.total / that.perPage), 1);
                        document.body.scrollTop = 0;
                    }
                },
            });
        },
        gotoActivityByAId(AId) {
            this.$router.push({
                name: "Activity",
                params: { AId: AId },
            });
        },
    },
}
</script>