<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container">
      <router-link to="/" class="navbar-brand">
        <img src="/img/Logo.png" alt="" v-if="!HeadData || !HeadData.SiteLogo" />
        <img :src="HeadData.SiteLogo" alt="" v-if="HeadData && HeadData.SiteLogo" />
      </router-link>
      <ul class="submenu" v-if="HeadData">
        <li>
          <a href="">:::</a>
        </li>
        <li v-for="(MenuData, index) in HeadData.Header_RightUp_Menu" :key="index">
          <a v-if="MenuData.Menu.Type == 0" href="javascript:void(0)">{{
            MenuData.Menu.Title
          }}</a>
          <a v-if="MenuData.Menu.Type == 1" href="javascript:void(0)" @click="gotoCategoryByCId(MenuData.Menu.CId)">{{
            MenuData.Menu.Title }}</a>
          <a v-if="MenuData.Menu.Type == 2" href="javascript:void(0)" @click="gotoPostByPId(MenuData.Menu.PId)">{{
            MenuData.Menu.Title }}</a>
          <a v-if="MenuData.Menu.Type == 3" href="javascript:void(0)"
            @click="gotoUrl(MenuData.Menu.Url, MenuData.Menu.Blank)">{{ MenuData.Menu.Title }}</a>
        </li>
        <li>
          <a href="javascript:void(0)" @click="changeLang('en')" v-if="local == 'zh_tw'">
            English</a>
          <a href="javascript:void(0)" @click="changeLang('zh_tw')" v-if="local == 'en'">
            中文版</a>
        </li>
        <li class="search_box">
          <div class="form-group">
            <input type="text" class="form-control" :placeholder="$t('輸入關鍵字')" v-model="Keyword"
              v-on:keyup.enter="search()" />
            <button @click="search()"><img src="/img/search.svg" alt="" /></button>
          </div>
        </li>
      </ul>
    </div>
    <div class="container" v-if="HeadData && HeadData.Header_Menu">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="line1"></span>
        <span class="line2"></span>
        <span class="line3"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav menu">
          <li class="nav-item" :class="MenuData.SubMenus ? 'dropdown' : ''"
            v-for="(MenuData, index) in HeadData.Header_Menu" :key="index">
            <a v-if="MenuData.Menu.Type == 1" class="nav-link" href="javascript:void(0)"
              @click="gotoCategoryByCId(MenuData.Menu.CId)">{{ MenuData.Menu.Title }}</a>
            <a v-if="MenuData.Menu.Type == 2" class="nav-link" href="javascript:void(0)"
              @click="gotoPostByPId(MenuData.Menu.PId)">{{ MenuData.Menu.Title }}</a>
            <a v-if="MenuData.Menu.Type == 3" class="nav-link" href="javascript:void(0)"
              @click="gotoUrl(MenuData.Menu.Url, MenuData.Menu.Blank)">{{ MenuData.Menu.Title }}</a>
            <a v-if="MenuData.Menu.Type == 0" class="nav-link dropdown-toggle" title="" role="button"
              data-toggle="dropdown" aria-expanded="false" href="javascript:void(0)">{{ MenuData.Menu.Title }}</a>
            <div class="dropdown-menu" v-if="MenuData.Menu.Type == 0">
              <a v-for="(MenuData_Sub, index) in MenuData.SubMenus" class="dropdown-item" href="javascript:void(0)"
                title="" @click="goto(MenuData_Sub)">
                {{ MenuData_Sub.Title }}
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import VueCookies from "vue-cookies";
Vue.use(VueAxios, axios);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
  zh_tw: {
    輸入關鍵字: "輸入關鍵字",
  },
  en: {
    輸入關鍵字: "Keyword",
  },
};

const i18n = new VueI18n({
  locale: "zh_tw",
  messages,
});

export default {
  name: "Head",
  i18n,
  data() {
    return {
      HeadData: {},
      local: "zh_tw",
      Keyword: '',
    };
  },
  created: function () {
    Vue.axios
      .get(
        this.$store.state.src + this.$store.state.subDirectory + "/Html/Head",
        {
          params: { lang: $cookies.get("aow_site_lang") },
        }
      )
      .then((response) => {
        this.HeadData = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted: function () {
    // 漢堡紐
    $("nav .navbar-toggler").click(function () {
      $("nav .navbar-toggler span").toggleClass("add");
    });
    if ($cookies.isKey("aow_site_lang")) {
      i18n.locale = $cookies.get("aow_site_lang");
      this.local = $cookies.get("aow_site_lang");
    } else {
      i18n.locale = $cookies.get("aow_site_lang");
      this.local = "zh_tw";
    }
  },
  methods: {
    gotoCategoryByCId: function (CId) {
      this.$router.push({
        name: "Category",
        params: { CId: CId },
      });
    },
    gotoPostByPId: function (PId) {
      this.$router.push({
        name: "Post",
        params: { PId: PId },
      });
    },
    gotoUrl: function (Url, Blank) {
      if (Blank) {
        window.open(Url, "_blank");
      } else {
        location.href = Url;
      }
    },
    goto: function (MenuData) {
      if (MenuData.Type == 1) {
        this.gotoCategoryByCId(MenuData.CId);
      } else if (MenuData.Type == 2) {
        this.gotoPostByPId(MenuData.PId);
      } else if (MenuData.Type == 3) {
        this.gotoUrl(MenuData.Url, MenuData.Blank);
      }
    },
    changeLang(lang) {
      $cookies.set("aow_site_lang", lang, "30d");
      if (this.$route.name != "Home") {
        this.$router.push({
          name: "Home",
          params: { },
        });
      } else {
        location.reload();
      }

    },
    search() {
      if (this.Keyword.length >= 1) {
        this.$router.push({
          name: "Search",
          params: { Keyword: this.Keyword },
        });
      } else {
        Swal.fire({
          text: "請至少輸入1個字元以上",
          icon: "info",
          allowOutsideClick: false,
          confirmButtonText: "確定",
          heightAuto: false,
        });
      }
    }
  },
};
</script>