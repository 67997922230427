import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    src: 'https://act-adopt.ahiqo.ntpc.gov.tw',
    subDirectory: '/Platform',
    //src: 'https://aow.thinker.tw',
    //subDirectory: '/Platform',
    //src: 'https://localhost:44312',
    //subDirectory: '',
    user: {
      token: "",
      isLogin: false
    }
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
