<template>
    <div>
        <Head />
        <Floating />
        <div class="wrapper tree_burial" v-if="BurialActivityData">
            <div class="main_bg">
                <nav class="breadcrumb_box">
                    <div class="container">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">寵物樹葬
                            </li>
                        </ol>
                    </div>
                </nav>
                <section class="main">
                    <div class="container">
                        <div class="title">
                            <h1>寵物樹葬</h1>
                        </div>
                        <div class="bg">
                            <div class="box">
                                <ul class="btn_box aa" v-if="BurialActivityData.OfficalWebBurialActivity">
                                    <li v-show="BurialActivityData.CanBooking">
                                        <router-link :to="{ name: 'BurialActivityBooking' }"><img
                                                src="/img/list_main_icon2.svg" alt="">寵物樹葬報名</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{ name: 'BurialActivitySearch' }"><img src="/img/search.svg"
                                                alt="">
                                            寵物樹葬查詢</router-link>
                                    </li>
                                </ul>
                                <ul class="text_box">
                                    <li>
                                        <div class="text">
                                            <h2>大自然的寶藏，樹葬之美</h2>
                                            <hr>
                                            <h3>生命的終章，給予最珍貴存在價值</h3>
                                            <p>樹葬著重於生命結束後之存在意義，使其回歸自然、享受自然，徜徉在花草藍天的廣闊草原，開啟另一扇美麗之窗。
                                                樹葬的解釋：遺體火化後，將其遺骨或骨灰埋入土中，栽種樹木、花卉、草坪加以紀念、追思與綠化環境。</p>
                                        </div>
                                        <div class="img_box">
                                            <img src="/img/001.png" alt="">
                                        </div>
                                    </li>
                                    <li>
                                        <div class="img_box">
                                            <img src="/img/002.png" alt="">
                                        </div>
                                        <div class="text">
                                            <h2>天堂裡的公園 . 景觀之美</h2>
                                            <hr>
                                            <h3>墓地公園化，創造毛寶貝溫馨的窩</h3>
                                            <p>新北市政府動保處整頓動物之家，設計景觀庭園，規劃步道、配置樹木花卉之開放空間給毛寶貝作為棲身之所，讓主人放心、毛寶貝也開心。</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="text">
                                            <h2>如何申請寵物樹葬服務</h2>
                                            <hr>
                                            <h3>資格條件：設籍新北市民、完成寵物登記、領有飼養寵物認證卡</h3>
                                            <p>新北市政府動物保護防疫處提供新北市民申請樹葬服務，只要符合資格條件可於動保處官方網站線上申請，
                                                若想進一步了解相關資訊，可撥打電話詢問(02)2959-6353將會有專人為您服務。</p>
                                        </div>
                                        <div class="img_box">
                                            <img src="/img/003.png" alt="">
                                        </div>

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <FooterMenu />
        <Footer />
    </div>
</template>

<script>
import Head from "@/components/Head.vue";
import Floating from "@/components/Floating.vue";
import FooterMenu from "@/components/FooterMenu.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

import Paginate from "vuejs-paginate";

Vue.component("paginate", Paginate);

export default {
    name: 'BurialActivity',
    components: {
        Head,
        Floating,
        FooterMenu,
        Footer,
    },
    data() {
        return {
            BurialActivityData: null,
        };
    },
    created: function () {
        Vue.axios
            .get(
                this.$store.state.src + this.$store.state.subDirectory + "/Html/BurialActivity",
                {
                    params: {},
                }
            )
            .then((response) => {
                this.BurialActivityData = response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    },
    mounted: function () { },
    methods: {},
}
</script>