<template>
    <div>
        <Head />
        <Floating />
        <div class="wrapper education">
            <div class="main_bg">
                <nav class="breadcrumb_box">
                    <div class="container">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
                            <li class=" breadcrumb-item active" aria-current="page">生命教育線上測驗</li>
                        </ol>
                    </div>
                </nav>
                <!-- 生命教育 -->
                <section class="main" v-show="LifeEduExamStep == 1">
                    <div class="container">
                        <div class="title">
                            <h1>生命教育</h1>
                        </div>
                        <div class="bg">
                            <ul class="step_box">
                                <li class="add">
                                    <div>基本資料</div>
                                </li>
                                <li>
                                    <div>觀看影片</div>
                                </li>
                                <li class="">
                                    <div>回答問卷</div>
                                </li>
                                <li>
                                    <div>完成</div>
                                </li>
                            </ul>
                            <div class="box" v-if="!IdentificationCheck">
                                <h2>個人資料</h2>
                                <div class="form">
                                    <div class="form-group full">
                                        <p style="font-weight: 500; margin-bottom: 0;">本人同意將以下個人資料提供給新北市政府作為推動市政服務(含新北市民會員系統相關服務)、各項便民優惠措施推廣及統計運用之用，並同意新北市政府定期查證上述個人資料之正確性。</p>
                                    </div>
                                    <div class="form-group">
                                        <label for="">身分證字號</label>
                                        <input type="text" class="form-control" aria-describedby="" placeholder="請輸入身分證字號"
                                            v-model="Identification">
                                    </div>
                                </div>
                                <hr>
                                <div class="btn_box">
                                    <button @click="loadInfo()">載入資料</button>
                                </div>
                            </div>
                            <div class="box" v-if="IdentificationCheck">
                                <h2>個人資料</h2>
                                <div class="form">
                                    <div class="form-group full">
                                        <p style="font-weight: 500; margin-bottom: 0;">本人同意將以下個人資料提供給新北市政府作為推動市政服務(含新北市民會員系統相關服務)、各項便民優惠措施推廣及統計運用之用，並同意新北市政府定期查證上述個人資料之正確性。</p>
                                    </div>
                                    <div class="form-group">
                                        <label for="">身分證字號</label>
                                        <input type="text" class="form-control" aria-describedby="" placeholder="請輸入身分證字號"
                                            v-model="Identification" disabled>
                                    </div>
                                    <div class="form-group">
                                        <label for="">姓名</label>
                                        <input type="text" class="form-control" aria-describedby="" placeholder="請輸入姓名"
                                            v-model="Name">
                                    </div>
                                    <div class="form-group">
                                        <label for="">電話號碼</label>
                                        <input type="text" class="form-control" aria-describedby="" placeholder="請輸入電話號碼"
                                            v-model="Phone">
                                    </div>
                                    <div class="form-group">
                                        <label for="">電子信箱</label>
                                        <input type="text" class="form-control" aria-describedby="" placeholder="請輸入電子信箱"
                                            v-model="Email">
                                    </div>
                                    <div class="form-group">
                                        <label for="">出生年月日</label>
                                        <input type="text" class="form-control format-date" aria-describedby=""
                                            v-model="Birthday" v-once>
                                    </div>
                                    <div class="form-group full">
                                        <label for="">收件地址</label>
                                        <input type="text" class="form-control" aria-describedby="" placeholder="請輸入收件地址"
                                            v-model="Address">
                                    </div>
                                </div>
                                <hr>
                                <div class="add_pet">
                                    <h2>寵物資料</h2>
                                    <div class="form pet_box" v-for="(Pet, index) in Pets" :key="index">
                                        <div class="form-group">
                                            <label for="">晶片號碼</label>
                                            <input type="text" class="form-control" aria-describedby=""
                                                placeholder="請輸入晶片號碼" v-model="Pet.ChipNumber">
                                        </div>
                                        <div class="form-group space"></div>
                                        <div class="form-group">
                                            <label for="">寵物名</label>
                                            <input type="text" class="form-control" aria-describedby="" placeholder="請輸入寵物名"
                                                v-model="Pet.PetName">
                                        </div>
                                        <div class="form-group">
                                            <label for="">寵物別</label>
                                            <select class="form-control" v-model="Pet.PetType">
                                                <option value="" disabled selected>請選擇寵物別</option>
                                                <option value="犬">犬</option>
                                                <option value="貓">貓</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label for="">出生年月日</label>
                                            <input type="text" class="form-control format-date" aria-describedby=""
                                                v-model="Pet.PetBirthday" v-once>
                                        </div>
                                        <div class="form-group">
                                            <label for="">性別</label>
                                            <select class="form-control" v-model="Pet.PetSex">
                                                <option value="" disabled selected>請選擇寵物性別</option>
                                                <option value="1">公</option>
                                                <option value="2">母</option>
                                                <option value="0">不確定</option>
                                            </select>
                                        </div>
                                        <hr :class="Pets.length > 1 && index != Pets.length - 1 ? 'add' : ''">
                                    </div>
                                    <div class="btn_box">
                                        <button class="add" @click="addPet()">新增寵物</button>
                                        <button class="cancel_btn" @click="removePet()"
                                            :style="Pets.length > 1 ? 'display: block;' : ''">刪除</button>
                                    </div>
                                </div>
                                <hr>
                                <div class="btn_box">
                                    <button @click="gotoStep(2)">開始上課</button>
                                    <button @click="gotoPetCertificationCard()" v-if="token">飼養寵物認證卡</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <!-- 生命教育 -->
                <section class="main" v-show="LifeEduExamStep == 2">
                    <div class="container">
                        <div class="title">
                            <h1>生命教育</h1>
                        </div>
                        <div class="bg">
                            <ul class="step_box">
                                <li class="">
                                    <div>基本資料</div>
                                </li>
                                <li class="add">
                                    <div>觀看影片</div>
                                </li>
                                <li class="">
                                    <div>回答問卷</div>
                                </li>
                                <li>
                                    <div>完成</div>
                                </li>
                            </ul>
                            <div class="box">
                                <div class="iframe_box" v-if="LifeEduExamData">
                                    <youtube :video-id="LifeEduExamData.LifeEduExamConfig.YoutubeUrl" ref="youtube"
                                        @playing="playing" @paused="paused"></youtube>
                                </div>
                                <div class="btn_box">
                                    <button class="countbox" :disabled="LastTime > 0" :style="LastTime > 0 ? 'cursor: no-drop;' : ''
                                        " @click="gotoStep(3)">閱讀倒數：{{ LastTime }}秒</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <!-- 生命教育 -->
                <section class="main" v-show="LifeEduExamStep == 3">
                    <div class="container">
                        <div class="title">
                            <h1>生命教育</h1>
                        </div>
                        <div class="bg">
                            <ul class="step_box">
                                <li class="">
                                    <div>基本資料</div>
                                </li>

                                <li class="">
                                    <div>觀看影片</div>
                                </li>
                                <li class="add">
                                    <div>回答問卷</div>
                                </li>
                                <li class="">
                                    <div>完成</div>
                                </li>
                            </ul>
                            <div class="box">
                                <div class="test" v-if="LifeEduExamData">
                                    <div v-for="(LifeEduExam, index) in LifeEduExamData.LifeEduExamA" :key="index">
                                        <hr v-if="index > 0">
                                        <div class="form-group">
                                            <label for="">{{ index + 1 }}.{{ LifeEduExam.QuestionContent }}</label>
                                            <div>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio"
                                                        :name="'Q' + LifeEduExam.LEEId" :id="'Q' + LifeEduExam.LEEId + '_Y'"
                                                        value="對">
                                                    <label class="form-check-label" :for="'Q' + LifeEduExam.LEEId + '_Y'">
                                                        對
                                                    </label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio"
                                                        :name="'Q' + LifeEduExam.LEEId" :id="'Q' + LifeEduExam.LEEId + '_N'"
                                                        value="錯">
                                                    <label class="form-check-label" :for="'Q' + LifeEduExam.LEEId + '_N'">
                                                        錯
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="btn_box">
                                    <button @click="sendAnswer()">提交</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <!-- 寵物樹葬表單3 -->
                <section class="main" v-show="LifeEduExamStep == 4">
                    <div class="container">
                        <div class="title">
                            <h1>生命教育</h1>
                        </div>
                        <div class="bg">
                            <ul class="step_box">
                                <li>
                                    <div>基本資料</div>
                                </li>
                                <li>
                                    <div>觀看影片</div>
                                </li>
                                <li class="">
                                    <div>回答問卷</div>
                                </li>
                                <li class="add">
                                    <div>完成</div>
                                </li>
                            </ul>
                            <div class="box">
                                <div class="done">
                                    <p>作答完畢</p>
                                    <img src="/img/line_icon.svg" alt="">
                                    <div class="text">
                                        <p>
                                            本次測驗分數: {{ Score }}
                                            <br>
                                            <span v-if="Passing">恭喜您已通過認證</span>
                                            <span v-if="!Passing">您未通過認證</span>
                                            <br v-if="Passing">
                                            <span v-if="Passing">飼養寵物認證卡已寄至您登記的信箱</span>
                                        </p>
                                    </div>
                                </div>
                                <div class="btn_box">
                                    <router-link tag="button" :to="{ name: 'Home' }">返回首頁</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <FooterMenu />
        <Footer />
    </div>
</template>

<script>
import Head from "@/components/Head.vue";
import Floating from "@/components/Floating.vue";
import FooterMenu from "@/components/FooterMenu.vue";
import Footer from "@/components/Footer.vue";
import Validate from "@/utils/validate.js";
import VueCookies from "vue-cookies";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

import VueYoutube from 'vue-youtube'
Vue.use(VueYoutube)

export default {
    name: 'LifeEduExam',
    components: {
        Head,
        Floating,
        FooterMenu,
        Footer,
    },
    data() {
        return {
            IdentificationCheck: false,
            LifeEduExamData: null,
            LastTime: 900,
            Passing: false,
            LifeEduExamStep: 1,
            Identification: '',
            Name: '',
            Phone: '',
            Email: '',
            Birthday: '',
            Address: '',
            Pets: [],
            loop: null,
            Score: 0,
            token: '',
        };
    },
    created: function () {
        this.Pets.push({
            ChipNumber: '',
            PetName: '',
            PetType: '',
            PetBirthday: '',
            PetSex: '',
        });
        Vue.axios
            .get(
                this.$store.state.src +
                this.$store.state.subDirectory +
                "/Html/LifeEduExam",
                {
                    params: {},
                }
            )
            .then((response) => {
                this.LifeEduExamData = response.data;
                this.LastTime = this.LifeEduExamData.LifeEduExamConfig.LimitViewTime;
            })
            .catch((error) => {
                console.log(error);
            });
    },
    updated: function () {
        $(".format-date").each(function (index) {
            if ($(this).attr("placeholder") != "000/00/00") {
                $(this).mask("000/00/00", { placeholder: "000/00/00" });
            }
        });
    },
    mounted: function () {

    },
    methods: {
        gotoStep(step) {
            if (this.LifeEduExamStep == 1 && step == 2) {
                if (this.Identification == "") {
                    Swal.fire({
                        title: "錯誤",
                        text: "請輸入身分證字號",
                        icon: "error",
                        allowOutsideClick: false,
                        confirmButtonText: "確定",
                        heightAuto: false,
                    });
                    return;
                }
                if (this.Name == "") {
                    Swal.fire({
                        title: "錯誤",
                        text: "請輸入姓名",
                        icon: "error",
                        allowOutsideClick: false,
                        confirmButtonText: "確定",
                        heightAuto: false,
                    });
                    return;
                }
                if (this.Phone == "") {
                    Swal.fire({
                        title: "錯誤",
                        text: "請輸入電話號碼",
                        icon: "error",
                        allowOutsideClick: false,
                        confirmButtonText: "確定",
                        heightAuto: false,
                    });
                    return;
                } else if (!Validate.reg_phone(this.Phone)) {
                    Swal.fire({
                        title: "錯誤",
                        text: "電話號碼格式錯誤",
                        icon: "error",
                        allowOutsideClick: false,
                        confirmButtonText: "確定",
                        heightAuto: false,
                    });
                    return;
                }
                if (this.Email == "") {
                    Swal.fire({
                        title: "錯誤",
                        text: "請輸入電子信箱",
                        icon: "error",
                        allowOutsideClick: false,
                        confirmButtonText: "確定",
                        heightAuto: false,
                    });
                    return;
                } else if (!Validate.reg_email(this.Email)) {
                    Swal.fire({
                        title: "錯誤",
                        text: "電子信箱格式錯誤",
                        icon: "error",
                        allowOutsideClick: false,
                        confirmButtonText: "確定",
                        heightAuto: false,
                    });
                    return;
                }
                if (!this.Birthday || this.Birthday == "") {
                    Swal.fire({
                        title: "錯誤",
                        text: "請輸入出生年月日",
                        icon: "error",
                        allowOutsideClick: false,
                        confirmButtonText: "確定",
                        heightAuto: false,
                    });
                    return;
                }
                if (this.Address == "") {
                    Swal.fire({
                        title: "錯誤",
                        text: "請輸入收件地址",
                        icon: "error",
                        allowOutsideClick: false,
                        confirmButtonText: "確定",
                        heightAuto: false,
                    });
                    return;
                }
                var PetError = false;
                $.each(this.Pets, function (index, Pet) {
                    if (Pet.ChipNumber == "" ||
                        Pet.PetName == "" ||
                        Pet.PetType == "" ||
                        Pet.PetSex == "") {
                        PetError = true;
                        return;
                    }
                    if (PetError) {
                        Swal.fire({
                            title: "錯誤",
                            text: "請確實輸入寵物資料",
                            icon: "error",
                            allowOutsideClick: false,
                            confirmButtonText: "確定",
                            heightAuto: false,
                        });
                        return;
                    }
                });
                this.LifeEduExamStep = 2;
                this.$refs.youtube.player.playVideo();
            } else if (this.LifeEduExamStep == 2 && step == 3) {
                this.$refs.youtube.player.pauseVideo();
                this.LifeEduExamStep = 3;
            }
        },
        addPet() {
            this.Pets.push({
                ChipNumber: '',
                PetName: '',
                PetType: '',
                PetBirthday: '',
                PetSex: '',
            });
        },
        removePet() {
            if (this.Pets.length > 1) {
                this.Pets.splice(this.Pets.length - 1);
            }
        },
        playing() {
            let that = this;
            if (!this.loop) {
                this.loop = window.setInterval(function () {
                    that.LastTime -= 1;
                    if (that.LastTime <= 0) {
                        window.clearInterval(that.loop);
                        $(".countbox").html("開始測驗");
                    }
                }, 1000);
            }
        },
        paused() {
            window.clearInterval(this.loop);
            this.loop = null;
        },
        sendAnswer() {
            var Answers = new Array();
            // 是非題
            $.each(this.LifeEduExamData.LifeEduExamA, function (index, LifeEduExam) {
                var jObj = new Object;
                jObj.LEEId = LifeEduExam.LEEId;
                jObj.Answer = $('input[name=Q' + LifeEduExam.LEEId + ']:checked').val();
                if (!jObj.Answer) {
                    jObj.Answer = '';
                }
                Answers = Answers.concat(jObj);
            });
            // 顯示請稍候
            Swal.fire({
                title: "請稍候...",
                allowOutsideClick: false,
                showConfirmButton: false,
                heightAuto: false,
                willOpen: function () {
                    Swal.showLoading();
                },
            });
            let that = this;
            $.ajax({
                url:
                    that.$store.state.src +
                    that.$store.state.subDirectory +
                    "/Html/LifeEduExam",
                type: "post",
                data: {
                    Identification: that.Identification,
                    Name: that.Name,
                    Phone: that.Phone,
                    Email: that.Email,
                    Birthday: that.Birthday,
                    Address: that.Address,
                    Pets: JSON.stringify(that.Pets),
                    ExamA: JSON.stringify(that.LifeEduExamData.LifeEduExamA),
                    Answers: JSON.stringify(Answers),
                },
                dataType: "json",
                error: function () { },
                success: function (res) {
                    // 判斷結果
                    if (res.ok) {
                        that.Passing = res.Passing;
                        that.Score = res.Score;
                        Swal.fire({
                            title: "送出完成",
                            icon: "success",
                            allowOutsideClick: false,
                            showConfirmButton: false,
                            heightAuto: false,
                            timer: 1500,
                            willClose: () => {
                                that.LifeEduExamStep = 4;
                            },
                        });
                    } else if (res.error) {
                        Swal.fire({
                            title: "錯誤",
                            text: res.error,
                            icon: "error",
                            allowOutsideClick: false,
                            confirmButtonText: "確定",
                            heightAuto: false,
                        });
                    }
                },
            });
        },
        loadInfo() {
            if (this.Identification == "") {
                Swal.fire({
                    title: "錯誤",
                    text: "請輸入身分證字號",
                    icon: "error",
                    allowOutsideClick: false,
                    confirmButtonText: "確定",
                    heightAuto: false,
                });
                return;
            }
            let that = this;
            $.ajax({
                url:
                    that.$store.state.src +
                    that.$store.state.subDirectory +
                    "/Html/LifeEduExamCheck",
                type: "post",
                data: {
                    Identification: that.Identification,
                },
                dataType: "json",
                error: function () { },
                success: function (res) {
                    // 判斷結果
                    if (res.ok) {
                        that.Name = res.Name;
                        that.Phone = res.Phone;
                        that.Email = res.Email;
                        that.Birthday = res.Birthday;
                        that.Address = res.Address;
                        that.Pets = jQuery.parseJSON(res.Pets);
                        that.token = res.token;
                        that.IdentificationCheck = true;
                    } else if (res.error) {
                        Swal.fire({
                            title: "錯誤",
                            text: res.error,
                            icon: "error",
                            allowOutsideClick: false,
                            confirmButtonText: "確定",
                            heightAuto: false,
                        });
                    }
                },
            });
        },
        gotoPetCertificationCard() {
            let route = this.$router.resolve({
                name: "PetCertificationCard",
                params: { token: this.token },
            });
            window.open(route.href, '_blank');
        }
    }
}
</script>