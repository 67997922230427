<template>
    <div>
        <Head />
        <Floating />
        <div class="wrapper tree_burial search">
            <div class="main_bg">
                <nav class="breadcrumb_box">
                    <div class="container">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
                            <li class="breadcrumb-item">
                                <router-link :to="{ name: 'BurialActivity' }">寵物樹葬</router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">寵物樹葬報名查詢
                            </li>
                        </ol>
                    </div>
                </nav>
                <section class="main">
                    <div class="container">
                        <div class="title">
                            <h1>寵物樹葬報名查詢</h1>
                        </div>
                        <div class="bg">
                            <div class="box">
                                <div class="form">
                                    <p><img src="/img/service_btn.svg" alt="">輸入「身分證、姓名、手機、寵物姓名」即可搜尋</p>
                                    <div class="form-group">
                                        <label for="">身分證字號<span>*</span></label>
                                        <input type="" class="form-control" id="" aria-describedby=""
                                            placeholder="請輸入身分證字號" v-model="OwnerID">
                                    </div>
                                    <div class="form-group">
                                        <label for="">申請人姓名<span>*</span></label>
                                        <input type="" class="form-control" id="" aria-describedby=""
                                            placeholder="請輸入申請人姓名" v-model="OwnerName">
                                    </div>
                                    <div class="form-group">
                                        <label for="">申請人手機<span>*</span></label>
                                        <input type="" class="form-control" id="" aria-describedby=""
                                            placeholder="請輸入申請人手機" v-model="OwnerPhone">
                                    </div>
                                    <div class="form-group">
                                        <label for="">寵物姓名<span>*</span></label>
                                        <input type="" class="form-control" id="" aria-describedby=""
                                            placeholder="請輸入寵物姓名" v-model="PetName">
                                    </div>
                                    <!-- <div class="form-group">
                                        <label for="">驗證碼<span>*</span></label>
                                        <input class="form-control" placeholder="驗證碼" v-model="identifyCode_input" />
                                    </div>
                                    <div class="form-group">
                                        <div class="code">
                                            <Sidentify :identifyCode="identifyCode"></Sidentify>
                                            <a href="javascript:void(0)" @click="refreshCode()"><img
                                                    src="/img/icon-rotate.svg" alt="" /></a>
                                            <a href="javascript:void(0)" @click="playCode()"><img src="/img/icon-voice.svg"
                                                    alt="" /></a>
                                        </div>
                                    </div> -->
                                </div>
                                <div class="btn_box">
                                    <!-- <button :disabled="identifyCode != identifyCode_input" :style="identifyCode != identifyCode_input ? 'cursor: no-drop;' : ''
                                        " @click="SendSearch()">查詢</button> -->
                                    <button @click="SendSearch()">查詢</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <FooterMenu />
        <Footer />
    </div>
</template>

<script>
import Head from "@/components/Head.vue";
import Floating from "@/components/Floating.vue";
import FooterMenu from "@/components/FooterMenu.vue";
import Footer from "@/components/Footer.vue";
import Validate from "@/utils/validate.js";
import Sidentify from "@/components/Identify";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

import Paginate from "vuejs-paginate";

Vue.component("paginate", Paginate);

export default {
    name: 'BurialActivitySearch',
    components: {
        Head,
        Floating,
        FooterMenu,
        Footer,
        Sidentify,
    },
    data() {
        return {
            // identifyCode: "0511",
            // identifyCode_input: "",
            // identifyCodes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
            // identifyCode_sound: ["0.mp3", "5.mp3", "1.mp3", "1.mp3"],
            // num: 0,
            OwnerID: '',
            OwnerName: '',
            OwnerPhone: '',
            PetName: ''
        };
    },
    created: function () {
        // this.refreshCode();
    },
    mounted: function () { },
    methods: {
        async getRecaptchaToken() {
            await this.$recaptchaLoaded();
            const token = await this.$recaptcha('login');
            return token;
        },
        // randomNum(min, max) {
        //     return Math.floor(Math.random() * (max - min) + min);
        // },
        // refreshCode() {
        //     this.identifyCode = "";
        //     this.makeCode(this.identifyCodes, 4);
        // },
        // makeCode(o, l) {
        //     for (let i = 0; i < l; i++) {
        //         var code =
        //             this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
        //         this.identifyCode += code;
        //         this.identifyCode_sound[i] = code + ".mp3";
        //     }
        // },
        // playCode() {
        //     var sound_path = "/voice/numvoice/zh";
        //     var audio = new Audio(
        //         sound_path + "/" + this.identifyCode_sound[this.num]
        //     );
        //     audio.play();
        //     let that = this;
        //     audio.addEventListener(
        //         "ended",
        //         function () {
        //             that.num++;
        //             if (that.num < 4) that.playCode();
        //             if (that.num >= 4) that.num = 0;
        //         },
        //         false
        //     );
        // },
        async SendSearch() {
            if (this.OwnerID == "") {
                Swal.fire({
                    title: "錯誤",
                    text: "請輸入身分證字號",
                    icon: "error",
                    allowOutsideClick: false,
                    confirmButtonText: "確定",
                    heightAuto: false,
                });
                return;
            }
            if (this.OwnerName == "") {
                Swal.fire({
                    title: "錯誤",
                    text: "請輸入申請人姓名",
                    icon: "error",
                    allowOutsideClick: false,
                    confirmButtonText: "確定",
                    heightAuto: false,
                });
                return;
            }
            if (this.OwnerPhone == "") {
                Swal.fire({
                    title: "錯誤",
                    text: "請輸入連絡電話",
                    icon: "error",
                    allowOutsideClick: false,
                    confirmButtonText: "確定",
                    heightAuto: false,
                });
                return;
            } else if (!Validate.reg_phone(this.OwnerPhone)) {
                Swal.fire({
                    title: "錯誤",
                    text: "連絡電話格式錯誤",
                    icon: "error",
                    allowOutsideClick: false,
                    confirmButtonText: "確定",
                    heightAuto: false,
                });
                return;
            }
            if (this.PetName == "") {
                Swal.fire({
                    title: "錯誤",
                    text: "請輸入寵物姓名",
                    icon: "error",
                    allowOutsideClick: false,
                    confirmButtonText: "確定",
                    heightAuto: false,
                });
                return;
            }
            // 我不是機器人
            let recaptchaToken = await this.getRecaptchaToken();
            // 顯示請稍候
            Swal.fire({
                title: "請稍候...",
                allowOutsideClick: false,
                showConfirmButton: false,
                heightAuto: false,
                willOpen: function () {
                    Swal.showLoading();
                },
            });
            let that = this;
            $.ajax({
                url:
                    that.$store.state.src +
                    that.$store.state.subDirectory +
                    "/Html/BurialActivitySearch",
                type: "post",
                data: {
                    OwnerID: that.OwnerID,
                    OwnerName: that.OwnerName,
                    OwnerPhone: that.OwnerPhone,
                    PetName: that.PetName,
                    token: recaptchaToken,
                },
                dataType: "json",
                error: function () { },
                success: function (res) {
                    // 判斷結果
                    if (res.ok) {
                        //that.identifyCode_input = '';
                        //that.refreshCode();
                        if (res.BAId != -1) {
                            var Approve = '審核中';
                            var icon = 'info';
                            if (res.Approve == 1) {
                                Approve = '通過';
                                icon = 'success';
                            } else if (res.Approve == 2) {
                                Approve = '未通過';
                                icon = 'error';
                            }
                            Swal.fire({
                                title: "您的報名編號為: " + res.BAId,
                                text: "審核結果: " + Approve,
                                icon: icon,
                                allowOutsideClick: false,
                                confirmButtonText: "確定",
                                heightAuto: false,
                            });
                        } else {
                            Swal.fire({
                                title: "查無報名資料",
                                icon: "info",
                                allowOutsideClick: false,
                                confirmButtonText: "確定",
                                heightAuto: false,
                            });
                        }
                    } else if (res.error) {
                        Swal.fire({
                            title: "錯誤",
                            text: res.error,
                            icon: "error",
                            allowOutsideClick: false,
                            confirmButtonText: "確定",
                            heightAuto: false,
                        });
                    }
                },
            });
        }
    },
}
</script>