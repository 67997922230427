<template>
    <div>
        <span id="httpObject"></span>
        <Head />
        <Floating />
        <div class="wrapper login_test">
            <div class="main_bg">
                <nav class="breadcrumb_box">
                    <div class="container">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
                            <li class=" breadcrumb-item active" aria-current="page">寵物業資格考試系統</li>
                        </ol>
                    </div>
                </nav>
                <section class="main" v-show="ExamStep == 1">
                    <div class="container">
                        <div class="title">
                            <h1>寵物業資格考試系統</h1>
                        </div>
                        <div class="bg">
                            <div class="box">
                                <div class="login">
                                    <h2>自然人憑證會員登入</h2>
                                    <div class="form-group">
                                        <label for="" style="flex-basis: 80px;">讀卡機</label>
                                        <select id="slotDescription" class="form-control"></select>
                                    </div>
                                    <div class="form-group" v-if="CanLogin">
                                        <label for="" style="flex-basis: 80px;">PIN</label>
                                        <input type="password" class="form-control" aria-describedby="" placeholder="請輸入PIN"
                                            v-model="PINCode">
                                    </div>
                                    <div class="btn_box">
                                        <button @click="reload()" v-if="!CanLogin">重新載入</button>
                                        <button @click="loadMember()" v-if="CanLogin">登入</button>
                                    </div>
                                </div>
                                <div class="text">
                                    <h3>憑證登入說明</h3>
                                    <p>
                                        使用憑證登入必須安裝以下軟體
                                        <br>
                                        1.1 讀卡機驅動程式(由讀卡機開發商提供)
                                        <br>
                                        1.2 <a href="https://moica.nat.gov.tw/download_1.html" target="_blank">中華電信HiCOS
                                            client API</a>
                                        <br>
                                        1.3 本站提供之ActiveX
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="main" v-show="ExamStep == 2">
                    <div class="container">
                        <div class="title">
                            <h1>寵物業資格考試系統</h1>
                        </div>
                        <div class="bg">
                            <div class="box">
                                <div class="login personal" v-if="ExamMember">
                                    <h2>個人資料</h2>
                                    <div class="form-group">
                                        <label for="">姓名</label>
                                        <input type="text" class="form-control" aria-describedby="" placeholder="請輸入姓名"
                                            v-model="ExamMember.Name" disabled>
                                    </div>
                                    <div class="form-group">
                                        <label for="">身分證字號</label>
                                        <input type="text" class="form-control" aria-describedby="" placeholder="請輸入身分證字號"
                                            v-model="ExamMember.Identification">
                                    </div>
                                    <div class="form-group">
                                        <label for="">生日</label>
                                        <input type="text" class="form-control format-date" aria-describedby=""
                                            v-model="Birthday" v-once>
                                    </div>
                                    <div class="form-group">
                                        <label for="">電話號碼</label>
                                        <input type="text" class="form-control" aria-describedby="" placeholder="請輸入電話號碼"
                                            v-model="ExamMember.Phone">
                                    </div>
                                    <div class="btn_box">
                                        <button @click="saveAndLogin()">確認送出</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <FooterMenu />
        <Footer />
    </div>
</template>

<script>
import Head from "@/components/Head.vue";
import Floating from "@/components/Floating.vue";
import FooterMenu from "@/components/FooterMenu.vue";
import Footer from "@/components/Footer.vue";
import Validate from "@/utils/validate.js";
import errorcode from "@/utils/errorcode.js";

import VueCookies from "vue-cookies";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

export default {
    name: 'ExamLogin',
    components: {
        Head,
        Floating,
        FooterMenu,
        Footer,
    },
    data() {
        return {
            ExamStep: 1,
            PINCode: '',
            ExamMember: null,
            Birthday: '',
            CanLogin: false,
            timeoutId: null,
        };
    },
    created: function () { },
    updated: function () {
        $(".format-date").each(function (index) {
            if ($(this).attr("placeholder") != "000/00/00") {
                $(this).mask("000/00/00", { placeholder: "000/00/00" });
            }
        });
    },
    mounted: function () {
        this.myLoad();
        window.addEventListener('message', this.receiveMessage);
    },
    beforeDestroy: function () {
        window.removeEventListener('message', this.receiveMessage);
    },
    methods: {
        loadMember() {
            this.makeSignature();
        },
        saveAndLogin() {
            if (!this.ExamMember.Name || this.ExamMember.Name == "") {
                Swal.fire({
                    title: "錯誤",
                    text: "請輸入姓名",
                    icon: "error",
                    allowOutsideClick: false,
                    confirmButtonText: "確定",
                    heightAuto: false,
                });
                return;
            }
            if (!this.ExamMember.Identification || this.ExamMember.Identification == "") {
                Swal.fire({
                    title: "錯誤",
                    text: "請輸入身分證字號",
                    icon: "error",
                    allowOutsideClick: false,
                    confirmButtonText: "確定",
                    heightAuto: false,
                });
                return;
            }
            if (!this.Birthday || this.Birthday == "") {
                Swal.fire({
                    title: "錯誤",
                    text: "請輸入生日",
                    icon: "error",
                    allowOutsideClick: false,
                    confirmButtonText: "確定",
                    heightAuto: false,
                });
                return;
            }
            if (!this.ExamMember.Phone || this.ExamMember.Phone == "") {
                Swal.fire({
                    title: "錯誤",
                    text: "請輸入電話號碼",
                    icon: "error",
                    allowOutsideClick: false,
                    confirmButtonText: "確定",
                    heightAuto: false,
                });
                return;
            } else if (!Validate.reg_phone(this.ExamMember.Phone)) {
                Swal.fire({
                    title: "錯誤",
                    text: "電話號碼格式錯誤",
                    icon: "error",
                    allowOutsideClick: false,
                    confirmButtonText: "確定",
                    heightAuto: false,
                });
                return;
            }
            // 顯示請稍候
            Swal.fire({
                title: "請稍候...",
                allowOutsideClick: false,
                showConfirmButton: false,
                heightAuto: false,
                willOpen: function () {
                    Swal.showLoading();
                },
            });
            let that = this;
            $.ajax({
                url:
                    that.$store.state.src +
                    that.$store.state.subDirectory +
                    "/Html/ExamLogin",
                type: "post",
                data: {
                    token: $cookies.get("aow_login_token"),
                    Name: that.ExamMember.Name,
                    Identification: that.ExamMember.Identification,
                    Birthday: that.Birthday,
                    Phone: that.ExamMember.Phone
                },
                dataType: "json",
                error: function () { },
                success: function (res) {
                    // 判斷結果
                    if (res.ok) {
                        Swal.fire({
                            title: "登入成功",
                            icon: "success",
                            allowOutsideClick: false,
                            showConfirmButton: false,
                            heightAuto: false,
                            timer: 1500,
                            willClose: () => {
                                that.$router.push({
                                    name: "ExamStart",
                                });
                            },
                        });
                    } else if (res.error) {
                        Swal.fire({
                            title: "錯誤",
                            text: res.error,
                            icon: "error",
                            allowOutsideClick: false,
                            confirmButtonText: "確定",
                            heightAuto: false,
                        });
                    }
                },
            });
        },
        reload() {
            location.reload();
        },
        myLoad() {
            let that = this;
            var img = null;
            var ctx;
            var output = "";
            var ua = window.navigator.userAgent;
            if (ua.indexOf("MSIE") == -1 && ua.indexOf("Trident") == -1) //not IE
            {
                img = document.createElement("img");
                img.crossOrigin = "Anonymous";
                img.src = 'http://localhost:61161/p11Image.bmp';
                var canvas = document.createElement("canvas");
                canvas.width = 2000; canvas.height = 1;
                ctx = canvas.getContext('2d');
                img.onload = function () {
                    ctx.drawImage(img, 0, 0);
                    output = that.getImageInfo(ctx);
                    that.setOutput(output);
                };
                img.onerror = function () {
                    Swal.fire({
                        title: "錯誤",
                        text: "未安裝客戶端程式或未啟動服務",
                        icon: "error",
                        allowOutsideClick: false,
                        confirmButtonText: "確定",
                        heightAuto: false,
                    });
                };
            } else {
                document.getElementById("httpObject").innerHTML = '<OBJECT id="http" width=1 height=1 style="LEFT: 1px; TOP: 1px" type="application/x-httpcomponent" VIEWASTEXT></OBJECT>';
                output = postData("http://localhost:61161/pkcs11info", "");
                if (output == null) {
                    Swal.fire({
                        title: "錯誤",
                        text: "未安裝客戶端程式或未啟動服務",
                        icon: "error",
                        allowOutsideClick: false,
                        confirmButtonText: "確定",
                        heightAuto: false,
                    });
                    return;
                } else {
                    that.setOutput(output);
                }
            }
        },
        getImageInfo(ctx) {
            var output = "";
            for (var i = 0; i < 2000; i++) {
                var data = ctx.getImageData(i, 0, 1, 1).data;
                if (data[2] == 0) break;
                output = output + String.fromCharCode(data[2], data[1], data[0]);
            }
            if (output == "") output = '{"ret_code": 1979711501,"message": "執行檔錯誤或逾時"}';
            return output;
        },
        setOutput(output) {
            var ret = JSON.parse(output);
            if (ret.ret_code == 0x76000031) {
                alert(window.location.hostname + "非信任網站，請先加入信任網站");
            }
            //document.getElementById('info').value = JSON.stringify(ret, null, 2);
            var slots = ret.slots;
            var selectSlot = document.getElementById('slotDescription');
            selectSlot.innerHTML = "";
            if (slots.length == 0) {
                Swal.fire({
                    title: "錯誤",
                    text: "無法取得卡片資訊, 請確認後再試一次",
                    icon: "error",
                    allowOutsideClick: false,
                    confirmButtonText: "確定",
                    heightAuto: false,
                });
            } else {
                for (var index in slots) {
                    if (slots[index].token instanceof Object) {
                        var opt = document.createElement('option');
                        opt.value = slots[index].slotDescription;
                        opt.innerHTML = slots[index].slotDescription;// + " 卡號:[" + slots[index].token.serialNumber + "]";
                        selectSlot.appendChild(opt);
                    }
                }
                this.CanLogin = true;
            }
        },
        getTbsPackage() {
            var tbsData = {};
            tbsData["tbs"] = encodeURIComponent('TBS');
            tbsData["tbsEncoding"] = 'NONE';
            tbsData["hashAlgorithm"] = 'SHA256';
            tbsData["withCardSN"] = 'false';
            tbsData["pin"] = encodeURIComponent(this.PINCode);
            tbsData["nonce"] = '';
            tbsData["func"] = "MakeSignature";
            tbsData["signatureType"] = "PKCS7";
            var json = JSON.stringify(tbsData);
            return json;
        },
        makeSignature() {
            let that = this;
            var ua = window.navigator.userAgent;
            if (ua.indexOf("MSIE") != -1 || ua.indexOf("Trident") != -1) {
                Swal.fire({
                    title: "請稍候...",
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    heightAuto: false,
                    willOpen: function () {
                        Swal.showLoading();
                    },
                });
                var tbsPackage = that.getTbsPackage();
                document.getElementById("httpObject").innerHTML = '<OBJECT id="http" width=1 height=1 style="LEFT: 1px; TOP: 1px" type="application/x-httpcomponent" VIEWASTEXT></OBJECT>';
                var data = postData("http://localhost:61161/sign", "tbsPackage=" + tbsPackage);
                Swal.close();
                if (!data) {
                    Swal.fire({
                        title: "錯誤",
                        text: "未安裝客戶端程式或未啟動服務",
                        icon: "error",
                        allowOutsideClick: false,
                        confirmButtonText: "確定",
                        heightAuto: false,
                    });
                } else {
                    that.setSignature(data)
                }
            }
            else {
                that.postTarget = window.open("http://localhost:61161/popupForm", "簽章中", "height=200, width=200, left=100, top=20");
                that.timeoutId = setTimeout(function () {
                    if (that.postTarget) {
                        that.postTarget.close();
                        Swal.fire({
                            title: "錯誤",
                            text: "未安裝客戶端程式或未啟動服務",
                            icon: "error",
                            allowOutsideClick: false,
                            confirmButtonText: "確定",
                            heightAuto: false,
                        });
                    }
                }, 3500);
            }
        },
        postData(target, data) {
            if (!http.sendRequest) {
                return null;
            }
            http.url = target;
            http.actionMethod = "POST";
            var code = http.sendRequest(data);
            if (code != 0) return null;
            return http.responseText;
        },
        setSignature(signature) {
            var ret = JSON.parse(signature);
            if (ret.ret_code != 0) {
                var error = errorcode.MajorErrorReason(ret.ret_code);
                if (ret.last_error) {
                    error += " " + errorcode.MinorErrorReason(ret.last_error);
                }
                Swal.fire({
                    title: "錯誤",
                    text: error,
                    icon: "error",
                    allowOutsideClick: false,
                    confirmButtonText: "確定",
                    heightAuto: false,
                });
                return;
            }
            // 登入
            let that = this;
            $.ajax({
                url:
                    that.$store.state.src +
                    that.$store.state.subDirectory +
                    "/Html/GetExamMember",
                type: "post",
                data: {
                    signedData: ret.signature,
                    testlog: signature,
                },
                dataType: "json",
                error: function () { },
                success: function (res) {
                    // 判斷結果
                    if (res.ok) {
                        $cookies.set("aow_login_token", res.token, "1d");
                        that.ExamMember = jQuery.parseJSON(res.ExamMember);
                        that.Birthday = res.Birthday;
                        that.ExamStep = 2;
                        Swal.close();
                    } else if (res.error) {
                        Swal.fire({
                            title: "錯誤",
                            text: res.error,
                            icon: "error",
                            allowOutsideClick: false,
                            confirmButtonText: "確定",
                            heightAuto: false,
                        });
                    }
                },
            });
        },
        receiveMessage(event) {
            let that = this;
            if (event.origin != "http://localhost:61161")
                return;
            try {
                var ret = JSON.parse(event.data);
                if (ret.func) {
                    if (ret.func == "getTbs") {
                        clearTimeout(that.timeoutId);
                        var json = that.getTbsPackage()
                        that.postTarget.postMessage(json, "*");
                    } else if (ret.func == "sign") {
                        that.setSignature(event.data);
                    }
                } else {
                    if (console) console.error("no func");
                }
            } catch (e) {
                //errorhandle
                if (console) console.error(e);
            }
        },
    },
}
</script>