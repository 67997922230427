<template>
    <div>
        <Head />
        <Floating />
        <!-- 文字列表 -->
        <div class="wrapper list list_2" v-if="Posts">
            <!-- 背景 -->
            <div class="main_bg">
                <!-- 麵包屑 -->
                <nav class="breadcrumb_box">
                    <div class="container">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link to="/">{{ $t("首頁") }}</router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">{{ $t("搜尋結果") }}
                            </li>
                        </ol>
                    </div>
                </nav>
                <!-- 最新消息 列表頁 -->
                <section class="news">
                    <div class="container">
                        <div class="title">
                            <h1>{{ Keyword }} 搜尋結果</h1>
                            <!-- <div class="btn_box">
                                <button>全部消息</button>
                                <button>新聞發佈</button>
                                <button>公告消息</button>
                            </div> -->
                        </div>
                        <div class="bg">
                            <ul class="list show">
                                <li v-for="Post in Posts" :key="Post.PId">
                                    <a href="javascript:void(0)" @click="gotoPost(Post)">
                                        <!-- <div class="tag_box">
                                            <div class="tag">置頂</div>
                                            <div>新聞</div>
                                        </div> -->
                                        <div class="text">
                                            <p class="time">{{ Post.PublicStartTime | timeString("YYYY-MM-DD") }}</p>
                                            <p>{{ Post.Title }}</p>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <paginate :no-li-surround="false" :page-count="pageAll" :page-range="3" :margin-pages="1"
                            :click-handler="loadList" :prev-text="'«'" :next-text="'»'" :prev-link-class="'page-link'"
                            :next-link-class="'page-link'" :container-class="'pagination'" :page-class="'page-item'"
                            :page-link-class="'page-link'" :active-class="'active'" :hide-prev-next="false"
                            v-if="pageAll > 1">
                        </paginate>
                    </div>
                </section>
            </div>
        </div>
        <FooterMenu />
        <Footer />
    </div>
</template>

<script>
import Head from "@/components/Head.vue";
import Floating from "@/components/Floating.vue";
import FooterMenu from "@/components/FooterMenu.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import Paginate from "vuejs-paginate";
Vue.component("paginate", Paginate);

import VueI18n from "vue-i18n";
Vue.use(VueI18n);

const messages = {
    zh_tw: {
        首頁: "首頁",
        搜尋結果: "搜尋結果",
    },
    en: {
        首頁: "Home",
        電話: "Search",
    },
};

const i18n = new VueI18n({
    locale: "zh_tw",
    messages,
});

export default {
    name: 'Search',
    i18n,
    components: {
        Head,
        Floating,
        FooterMenu,
        Footer,
    },
    data() {
        return {
            Keyword: '',
            Posts: null,
            pageAll: 0,
            perPage: 5,
        };
    },
    created: function () {
        this.Keyword = this.$route.params.Keyword;
        this.loadList(1);
    },
    mounted: function () {
        if ($cookies.isKey("aow_site_lang")) {
            i18n.locale = $cookies.get("aow_site_lang");
            this.local = $cookies.get("aow_site_lang");
        } else {
            i18n.locale = $cookies.get("aow_site_lang");
            this.local = "zh_tw";
        }
    },
    beforeRouteUpdate(to, from, next) {
        this.Keyword = to.params.Keyword;
        this.loadList(1);
        next();
    },
    methods: {
        loadList: function (page) {
            var that = this;
            $.ajax({
                url:
                    this.$store.state.src +
                    this.$store.state.subDirectory +
                    "/Html/SearchList",
                type: "post",
                data: {
                    page: page,
                    perPage: that.perPage,
                    keyword: that.Keyword,
                },
                dataType: "json",
                error: function () { },
                success: function (res) {
                    if (res.status == 1) {
                        that.Posts = res.Posts;
                        that.perPage = res.perPage;
                        that.pageAll = Math.max(Math.ceil(res.total / that.perPage), 1);
                        document.body.scrollTop = 0;
                    }
                },
            });
        },
        gotoPost(Post) {
            if (Post.Type == 1) {
                if (Post.Blank) {
                    window.open(Post.Url, "_blank");
                } else {
                    location.href = Post.Url;
                }
            } else {
                this.$router.push({
                    name: "Post",
                    params: { PId: Post.PId },
                });
            }
        },
    },
}
</script>