<template>
    <div>
        <Head />
        <Floating />
        <div class="wrapper login_test" v-if="ExamMember">
            <div class="main_bg">
                <nav class="breadcrumb_box">
                    <div class="container">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
                            <li class=" breadcrumb-item active" aria-current="page">寵物業資格考試系統</li>
                        </ol>
                    </div>
                </nav>
                <section class="main">
                    <div class="container">
                        <div class="title">
                            <h1>寵物業資格考試系統</h1>
                        </div>
                        <div class="bg">
                            <div class="box">
                                <div class="login starts">
                                    <h2>寵物業資格考試</h2>
                                    <div class="text">
                                        <p>
                                            歡迎進入寵物資格考試系統
                                            <br>
                                            本次測驗答題時間為15分鐘
                                        </p>
                                        <p>
                                            選擇題{{ ExamConfig.MultipleChoiceCount }}題，每題{{ ExamConfig.MultipleChoiceScores
                                            }}分
                                        </p>
                                        <p>
                                            是非題{{ ExamConfig.TrueAndFalseCount }}題，每題{{ ExamConfig.TrueAndFalseScores }}分
                                        </p>
                                        <p>
                                            及格為{{ ExamConfig.PassingScore }}分，並可列印證書「寵物業資格考試及格證」；未達80分者則需兩星期後才能重新報考。
                                        </p>
                                        <br v-show="!CanExam" />
                                        <p v-show="!CanExam" style="text-align: center; color: red;">
                                            您目前無法進行測驗
                                        </p><p v-show="!CanExam" style="text-align: center; color: red;">
                                            下次可測驗時間: {{ NextCanExamTime }}
                                        </p>
                                    </div>
                                    <div class="form-check" v-show="CanExam">
                                        <input type="checkbox" class="form-check-input" id="cBoxPromise">
                                        <label class="form-check-label" for="cBoxPromise">本人作答無舞弊行為</label>
                                    </div>
                                    <div class="btn_box">
                                        <router-link tag="button" :to="{ name: 'ExamList' }">歷史紀錄</router-link>
                                        <button @click="startTest()" v-show="CanExam">開始測驗</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <FooterMenu />
        <Footer />
    </div>
</template>

<script>
import Head from "@/components/Head.vue";
import Floating from "@/components/Floating.vue";
import FooterMenu from "@/components/FooterMenu.vue";
import Footer from "@/components/Footer.vue";
import Validate from "@/utils/validate.js";
import VueCookies from "vue-cookies";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

export default {
    name: 'ExamStart',
    components: {
        Head,
        Floating,
        FooterMenu,
        Footer,
    },
    data() {
        return {
            ExamMember: null,
            ExamConfig: null,
            CanExam: false,
            NextCanExamTime: ""
        };
    },
    created: function () {
        let that = this;
        $.ajax({
            url:
                that.$store.state.src +
                that.$store.state.subDirectory +
                "/Html/GetExamMember",
            type: "post",
            data: {
                token: $cookies.get("aow_login_token")
            },
            dataType: "json",
            error: function () { },
            success: function (res) {
                // 判斷結果
                if (res.ok) {
                    that.ExamMember = jQuery.parseJSON(res.ExamMember);
                    that.ExamConfig = jQuery.parseJSON(res.ExamConfig);
                    that.CanExam = res.CanExam;
                    that.NextCanExamTime = res.NextCanExamTime;
                } else if (res.error) {
                    Swal.fire({
                        title: "錯誤",
                        text: res.error,
                        icon: "error",
                        allowOutsideClick: false,
                        confirmButtonText: "確定",
                        heightAuto: false,
                        willClose: () => {
                            that.$router.push({
                                name: "ExamLogin",
                            });
                        },
                    });
                }
            },
        });
    },
    updated: function () {
        $("#cBoxPromise").prop("checked", false);
    },
    mounted: function () { },
    methods: {
        startTest() {
            if (!$("#cBoxPromise").prop("checked")) {
                Swal.fire({
                    title: "錯誤",
                    text: "請先勾選「本人作答無舞弊行為」",
                    icon: "error",
                    allowOutsideClick: false,
                    confirmButtonText: "確定",
                    heightAuto: false,
                });
                return;
            }
            this.$router.push({
                name: "ExamPage",
            });
        }
    },
}
</script>