<template>
    <div>
        <Head />
        <Floating />
        <div class="wrapper tree_burial" v-show="BurialActivityBookingData">
            <div class="main_bg">
                <nav class="breadcrumb_box">
                    <div class="container">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
                            <li class="breadcrumb-item">
                                <router-link :to="{ name: 'BurialActivity' }">寵物樹葬</router-link>
                            </li>
                            <li class="breadcrumb-item active" aria-current="page">寵物樹葬報名
                            </li>
                        </ol>
                    </div>
                </nav>
                <section class="main">
                    <div class="container">
                        <div class="title">
                            <h1>寵物樹葬報名</h1>
                        </div>
                        <div class="bg">
                            <ul class="step_box">
                                <li :class="BookingStep == 1 ? 'add' : ''">
                                    <div>寵物資料</div>
                                </li>
                                <li :class="BookingStep == 2 ? 'add' : ''">
                                    <div>個人資料</div>
                                </li>
                                <li :class="BookingStep == 3 ? 'add' : ''">
                                    <div>完成</div>
                                </li>
                            </ul>
                            <div class="box" v-show="BookingStep == 1">
                                <div class="file_box">
                                    <p>上傳寵物照片</p>
                                    <div class="custom_file" style="height: 360px;" @click="getFile('petPhoto')">
                                        <div class="aa">
                                            <input type="file" class="custom-file-input"
                                                accept="image/jpg, image/jpeg, image/png" id="petPhoto"
                                                style="display: none;">
                                            <input type="hidden" value="" id="petPhoto_data" />
                                            <div id="petPhoto_preview" class="text upload_preview">
                                            </div>
                                            <div id="petPhoto_def" class="text">
                                                <div class="upload">點選此處選擇檔案上傳</div>
                                                <p>僅支援圖片且小於10MB以下的檔案</p>
                                                <div class="btn">上傳檔案</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="form">
                                    <div class="form-group">
                                        <label for="">晶片號碼</label>
                                        <input type="text" class="form-control" aria-describedby=""
                                            placeholder="請輸入晶片號碼，或無則填寫無" v-model="ChipNumber">
                                    </div>
                                    <div class="form-group">
                                        <label for="">寵物姓名</label>
                                        <input type="text" class="form-control" aria-describedby="" placeholder="請輸入寵物姓名"
                                            v-model="PetName">
                                    </div>
                                    <div class="form-group">
                                        <label for="">種類</label>
                                        <select class="form-control" v-model="PetType">
                                            <option value="" disabled selected>請選擇種類</option>
                                            <option value="犬">犬</option>
                                            <option value="貓">貓</option>
                                            <option value="鳥">鳥</option>
                                            <option value="鼠">鼠</option>
                                            <option value="兔">兔</option>
                                            <option value="其他">其他</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label for="">品種</label>
                                        <input type="text" class="form-control" aria-describedby="" placeholder="請輸入品種"
                                            v-model="PetKind">
                                    </div>
                                    <div class="form-group">
                                        <label for="">性別</label>
                                        <select class="form-control" v-model="PetSex">
                                            <option value="" disabled selected>請選擇性別</option>
                                            <option value="1">公</option>
                                            <option value="2">母</option>
                                            <option value="0">不確定</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label for="">年齡</label>
                                        <input type="number" class="form-control" aria-describedby="" placeholder="請輸入年齡"
                                            v-model="PetAge">
                                    </div>
                                    <div class="form-group full">
                                        <label for="">死亡原因</label>
                                        <input type="text" class="form-control" aria-describedby="" placeholder="請輸入死亡原因"
                                            v-model="PetDeadReason">
                                    </div>
                                </div>
                                <hr>
                                <div class="form">
                                    <div class="form-group">
                                        <label for="">地點</label>
                                        <select class="form-control" disabled v-model="BLId">
                                            <option v-if="BurialActivityBookingData"
                                                :value="BurialActivityBookingData.OfficalWebBurialLocation.BLId" disabled
                                                selected>{{
                                                    BurialActivityBookingData.OfficalWebBurialLocation.BLName }}</option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label for="">下葬方式</label>
                                        <select class="form-control" disabled v-model="BurialWay">
                                            <option value="樹葬" disabled selected>樹葬</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="btn_box">
                                    <button @click="gotoStep(2)">下一頁</button>
                                </div>
                            </div>
                            <div class="box" v-show="BookingStep == 2">
                                <div class="form">
                                    <div class="form-group">
                                        <label for="">申請人</label>
                                        <input type="" class="form-control" aria-describedby="" placeholder="請輸入申請人"
                                            v-model="OwnerName">
                                    </div>
                                    <div class="form-group">
                                        <label for="">身分證號碼</label>
                                        <input type="" class="form-control" aria-describedby="" placeholder="請輸入身分證號碼"
                                            v-model="OwnerID">
                                    </div>
                                    <div class="form-group">
                                        <label for="">手機</label>
                                        <input type="" class="form-control" aria-describedby="" placeholder="請輸入手機"
                                            v-model="OwnerPhone">
                                    </div>
                                    <div class="form-group">
                                        <label for="">電子信箱</label>
                                        <input type="" class="form-control" aria-describedby="" placeholder="請輸入電子信箱"
                                            v-model="OwnerEmail">
                                    </div>
                                    <div class="form-group full">
                                        <label for="">地址</label>
                                        <input type="" class="form-control" aria-describedby="" placeholder="請輸入地址"
                                            v-model="OwnerAddress">
                                    </div>
                                </div>
                                <hr>
                                <h2>附件上傳</h2>
                                <div class="form">
                                    <div class="upload_img">
                                        <p>身分證正面</p>
                                        <div class="custom_file">
                                            <input type="file" class="custom-file-input"
                                                accept="image/jpg, image/jpeg, image/png" id="ownerIDImage">
                                            <input type="hidden" value="" id="ownerIDImage_data" />
                                            <img id="ownerIDImage_preview" src="#" />
                                            <div id="ownerIDImage_def">
                                                <p>僅支援圖片且小於10MB以下的檔案</p>
                                                <div class="upload_btn">選擇檔案</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="upload_img">
                                        <p>身分證反面</p>
                                        <div class="custom_file">
                                            <input type="file" class="custom-file-input"
                                                accept="image/jpg, image/jpeg, image/png" id="ownerIDImageBack">
                                            <input type="hidden" value="" id="ownerIDImageBack_data" />
                                            <img id="ownerIDImageBack_preview" src="#" />
                                            <div id="ownerIDImageBack_def">
                                                <p>僅支援圖片且小於10MB以下的檔案</p>
                                                <div class="upload_btn">選擇檔案</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="form">
                                    <div class="upload_img">
                                        <p>飼養寵物認證卡正面</p>
                                        <div class="custom_file">
                                            <input type="file" class="custom-file-input"
                                                accept="image/jpg, image/jpeg, image/png" id="accreditationImage">
                                            <input type="hidden" value="" id="accreditationImage_data" />
                                            <img id="accreditationImage_preview" src="#" />
                                            <div id="accreditationImage_def">
                                                <p>僅支援圖片且小於10MB以下的檔案</p>
                                                <div class="upload_btn">選擇檔案</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="upload_img">
                                        <p>飼養寵物認證卡反面</p>
                                        <div class="custom_file">
                                            <input type="file" class="custom-file-input"
                                                accept="image/jpg, image/jpeg, image/png" id="accreditationImageBack">
                                            <input type="hidden" value="" id="accreditationImageBack_data" />
                                            <img id="accreditationImageBack_preview" src="#" />
                                            <div id="accreditationImageBack_def">
                                                <p>僅支援圖片且小於10MB以下的檔案</p>
                                                <div class="upload_btn">選擇檔案</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="form">
                                    <div class="upload_img">
                                        <p>寵物除戶證明</p>
                                        <div class="custom_file">
                                            <input type="file" class="custom-file-input"
                                                accept="image/jpg, image/jpeg, image/png" id="cancellationImage">
                                            <input type="hidden" value="" id="cancellationImage_data" />
                                            <img id="cancellationImage_preview" src="#" />
                                            <div id="cancellationImage_def">
                                                <p>僅支援圖片且小於10MB以下的檔案</p>
                                                <div class="upload_btn">選擇檔案</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="btn_box">
                                    <button @click="gotoStep(1)">回上一頁</button>
                                    <button @click="gotoStep(3)">確認送出</button>
                                </div>
                            </div>
                            <div class="box" v-show="BookingStep == 3">
                                <div class="done">
                                    <p>填寫完成<img src="/img/heart_icon.svg" alt=""></p>
                                    <img src="/img/line_icon.svg" alt="">
                                </div>
                                <div class="btn_box">
                                    <router-link tag="button" to="/BurialActivity">返回寵物樹葬</router-link>
                                    <router-link tag="button" to="/">首頁</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <FooterMenu />
        <Footer />
    </div>
</template>

<script>
import Head from "@/components/Head.vue";
import Floating from "@/components/Floating.vue";
import FooterMenu from "@/components/FooterMenu.vue";
import Footer from "@/components/Footer.vue";
import Validate from "@/utils/validate.js";
import Sidentify from "@/components/Identify";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

import Paginate from "vuejs-paginate";

Vue.component("paginate", Paginate);

export default {
    name: 'BurialActivityBooking',
    components: {
        Head,
        Floating,
        FooterMenu,
        Footer,
        Sidentify,
    },
    data() {
        return {
            BookingStep: 1,
            BurialActivityBookingData: null,
            PetPhoto: '',
            PetName: '',
            BLId: '',
            PetKind: '',
            PetType: '',
            PetAge: '0',
            PetSex: '',
            PetDeadReason: '',
            BurialWay: '',
            ChipNumber: '',
            OwnerName: '',
            OwnerPhone: '',
            OwnerID: '',
            OwnerEmail: '',
            OwnerAddress: '',
            OwnerIDImage: '',
            OwnerIDImageBack: '',
            AccreditationImage: '',
            AccreditationImageBack: '',
            CancellationImage: '',
        };
    },
    created: function () {
        Vue.axios
            .get(
                this.$store.state.src + this.$store.state.subDirectory + "/Html/BurialActivityBooking",
                {
                    params: {},
                }
            )
            .then((response) => {
                this.BurialActivityBookingData = response.data;
                this.BLId = this.BurialActivityBookingData.OfficalWebBurialLocation.BLId;
                this.BurialWay = "樹葬";
            })
            .catch((error) => {
                console.log(error);
            });
    },
    mounted: function () {
        var that = this;
        $(".custom-file-input").change(function () {
            if (this.files.length != 0) {
                // 顯示請稍候
                Swal.fire({
                    title: "請稍候...",
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    heightAuto: false,
                    willOpen: function () {
                        Swal.showLoading();
                    },
                });
                var id = $(this).attr("id");
                var file = this.files[0];
                // 不進行壓縮的情況要檢查大小
                if (file.size > 10485760) {
                    Swal.fire({
                        title: "錯誤",
                        text: "檔案大小超過10MB",
                        icon: "error",
                        allowOutsideClick: false,
                        confirmButtonText: "確定",
                        heightAuto: false,
                    });
                } else {
                    var reader = new FileReader();
                    reader.onloadend = function () {
                        $("#" + id).val("");
                        that.setImage(id, reader.result);
                    };
                    reader.onerror = function (error) {
                        $("#" + id).val("");
                        Swal.fire({
                            title: "錯誤",
                            html:
                                "處理圖片過程發生錯誤:<br />" +
                                error +
                                "<br />若持續發生此問題請聯繫客服人員, 造成不便敬請見諒",
                            icon: "error",
                            allowOutsideClick: false,
                            confirmButtonText: "確定",
                            heightAuto: false,
                        });
                    };
                    reader.readAsDataURL(file);
                }
            }
        });
    },
    methods: {
        gotoStep(step) {
            if (this.BookingStep == 1 && step == 2) {
                this.PetPhoto = $("#petPhoto_data").val();
                if (this.PetPhoto == "") {
                    Swal.fire({
                        title: "錯誤",
                        text: "請上傳寵物照片",
                        icon: "error",
                        allowOutsideClick: false,
                        confirmButtonText: "確定",
                        heightAuto: false,
                    });
                    return;
                }
                if (this.ChipNumber == "") {
                    Swal.fire({
                        title: "錯誤",
                        text: "請輸入晶片號碼",
                        icon: "error",
                        allowOutsideClick: false,
                        confirmButtonText: "確定",
                        heightAuto: false,
                    });
                    return;
                }
                if (this.PetName == "") {
                    Swal.fire({
                        title: "錯誤",
                        text: "請輸入寵物姓名",
                        icon: "error",
                        allowOutsideClick: false,
                        confirmButtonText: "確定",
                        heightAuto: false,
                    });
                    return;
                }
                if (this.PetType == "") {
                    Swal.fire({
                        title: "錯誤",
                        text: "請選擇種類",
                        icon: "error",
                        allowOutsideClick: false,
                        confirmButtonText: "確定",
                        heightAuto: false,
                    });
                    return;
                }
                if (this.PetKind == "") {
                    Swal.fire({
                        title: "錯誤",
                        text: "請輸入品種",
                        icon: "error",
                        allowOutsideClick: false,
                        confirmButtonText: "確定",
                        heightAuto: false,
                    });
                    return;
                }
                if (this.PetSex == "") {
                    Swal.fire({
                        title: "錯誤",
                        text: "請選擇姓別",
                        icon: "error",
                        allowOutsideClick: false,
                        confirmButtonText: "確定",
                        heightAuto: false,
                    });
                    return;
                }
                if (this.PetAge == "") {
                    Swal.fire({
                        title: "錯誤",
                        text: "請輸入年齡",
                        icon: "error",
                        allowOutsideClick: false,
                        confirmButtonText: "確定",
                        heightAuto: false,
                    });
                    return;
                }
                if (this.PetAge == "") {
                    Swal.fire({
                        title: "錯誤",
                        text: "請輸入死亡原因",
                        icon: "error",
                        allowOutsideClick: false,
                        confirmButtonText: "確定",
                        heightAuto: false,
                    });
                    return;
                }
                this.BookingStep = 2;
            } else if (this.BookingStep == 2 && step == 3) {
                if (this.OwnerName == "") {
                    Swal.fire({
                        title: "錯誤",
                        text: "請輸入申請人",
                        icon: "error",
                        allowOutsideClick: false,
                        confirmButtonText: "確定",
                        heightAuto: false,
                    });
                    return;
                }
                if (this.OwnerID == "") {
                    Swal.fire({
                        title: "錯誤",
                        text: "請輸入身分證號碼",
                        icon: "error",
                        allowOutsideClick: false,
                        confirmButtonText: "確定",
                        heightAuto: false,
                    });
                    return;
                }
                if (this.OwnerPhone == "") {
                    Swal.fire({
                        title: "錯誤",
                        text: "請輸入手機",
                        icon: "error",
                        allowOutsideClick: false,
                        confirmButtonText: "確定",
                        heightAuto: false,
                    });
                    return;
                } else if (!Validate.reg_phone(this.OwnerPhone)) {
                    Swal.fire({
                        title: "錯誤",
                        text: "手機格式錯誤",
                        icon: "error",
                        allowOutsideClick: false,
                        confirmButtonText: "確定",
                        heightAuto: false,
                    });
                    return;
                }
                if (this.OwnerEmail == "") {
                    Swal.fire({
                        title: "錯誤",
                        text: "請輸入電子信箱",
                        icon: "error",
                        allowOutsideClick: false,
                        confirmButtonText: "確定",
                        heightAuto: false,
                    });
                    return;
                } else if (!Validate.reg_email(this.OwnerEmail)) {
                    Swal.fire({
                        title: "錯誤",
                        text: "電子信箱格式錯誤",
                        icon: "error",
                        allowOutsideClick: false,
                        confirmButtonText: "確定",
                        heightAuto: false,
                    });
                    return;
                }
                if (this.OwnerAddress == "") {
                    Swal.fire({
                        title: "錯誤",
                        text: "請輸入地址",
                        icon: "error",
                        allowOutsideClick: false,
                        confirmButtonText: "確定",
                        heightAuto: false,
                    });
                    return;
                }
                this.OwnerIDImage = $("#ownerIDImage_data").val();
                this.OwnerIDImageBack = $("#ownerIDImageBack_data").val();
                this.AccreditationImage = $("#accreditationImage_data").val();
                this.AccreditationImageBack = $("#accreditationImageBack_data").val();
                this.CancellationImage = $("#cancellationImage_data").val();
                if (this.OwnerIDImage == "" ||
                    this.OwnerIDImageBack == "" ||
                    this.AccreditationImage == "" ||
                    this.AccreditationImageBack == "" ||
                    this.CancellationImage == "") {
                    Swal.fire({
                        title: "錯誤",
                        text: "請確認已上傳所有附件",
                        icon: "error",
                        allowOutsideClick: false,
                        confirmButtonText: "確定",
                        heightAuto: false,
                    });
                    return;
                }
                // 顯示請稍候
                Swal.fire({
                    title: "請稍候...",
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    heightAuto: false,
                    willOpen: function () {
                        Swal.showLoading();
                    },
                });
                let that = this;
                $.ajax({
                    url:
                        that.$store.state.src +
                        that.$store.state.subDirectory +
                        "/Html/BurialActivityBooking",
                    type: "post",
                    data: {
                        PetPhoto: that.PetPhoto,
                        PetName: that.PetName,
                        BLId: that.BLId,
                        PetKind: that.PetKind,
                        PetType: that.PetType,
                        PetAge: that.PetAge,
                        PetSex: that.PetSex,
                        PetDeadReason: that.PetDeadReason,
                        BurialWay: that.BurialWay,
                        ChipNumber: that.ChipNumber,
                        OwnerName: that.OwnerName,
                        OwnerPhone: that.OwnerPhone,
                        OwnerID: that.OwnerID,
                        OwnerEmail: that.OwnerEmail,
                        OwnerAddress: that.OwnerAddress,
                        OwnerIDImage: that.OwnerIDImage,
                        OwnerIDImageBack: that.OwnerIDImageBack,
                        AccreditationImage: that.AccreditationImage,
                        AccreditationImageBack: that.AccreditationImageBack,
                        CancellationImage: that.CancellationImage,
                    },
                    dataType: "json",
                    error: function () { },
                    success: function (res) {
                        // 判斷結果
                        if (res.ok) {
                            that.BookingStep = 3;
                            Swal.close();
                        } else if (res.error) {
                            Swal.fire({
                                title: "錯誤",
                                text: res.error,
                                icon: "error",
                                allowOutsideClick: false,
                                confirmButtonText: "確定",
                                heightAuto: false,
                            });
                        }
                    },
                });
            } else {
                this.BookingStep = step;
            }
            $('html,body').animate({ scrollTop: 0 }, 0);
        },
        setImage(id, rst) {
            let that = this;
            if (id == 'petPhoto') {
                $("#" + id + "_preview").css("background-image", 'url("' + rst + '")');
            } else {
                $("#" + id + "_preview").attr("src", rst);
                $("#" + id + "_preview").css("opacity", 1);
            }
            $("#" + id + "_data").val(rst);
            $("#" + id + "_def").hide();
            $("#" + id + "_preview").show();
            Swal.close();
        },
        getFile(id) {
            $("#" + id).click();
        },
    },
}
</script>