<template>
    <section class="footer_menu" v-if="FooterMenuData">
        <div class="btn">
            <div class="line1"></div>
            <div class="line2"></div>
        </div>
        <div class="bg">
            <div class="container">
                <ul class="list">
                    <li v-for="(MenuData, index) in FooterMenuData.Footer_Menu">
                        <a class="title" href="javascript:void(0)" @click="goto(MenuData.Menu)">{{ MenuData.Menu.Title
                        }}</a>
                        <a href="javascript:void(0)" @click="goto(MenuData_Sub)"
                            v-for="(MenuData_Sub, index) in MenuData.SubMenus">{{ MenuData_Sub.Title }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>
      
<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

export default {
    name: "FooterMenu",
    data() {
        return { FooterMenuData: {}, };
    },
    created: function () {
        Vue.axios
            .get(
                this.$store.state.src + this.$store.state.subDirectory + "/Html/FooterMenu",
                {
                    params: { lang: $cookies.get("aow_site_lang") },
                }
            )
            .then((response) => {
                this.FooterMenuData = response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    },
    mounted: function () {
        // 首頁 胖頁尾 開闔
        $(".footer_menu .btn").click(function () {
            $(".footer_menu .bg").slideToggle("");
            $(".footer_menu .btn div").toggleClass("add")
            $(".footer_menu").toggleClass("add")
        });
    },
    methods: {
        gotoCategoryByCId: function (CId) {
            this.$router.push({
                name: "Category",
                params: { CId: CId },
            });
        },
        gotoPostByPId: function (PId) {
            this.$router.push({
                name: "Post",
                params: { PId: PId },
            });
        },
        gotoUrl: function (Url, Blank) {
            if (Blank) {
                window.open(Url, "_blank");
            } else {
                location.href = Url;
            }
        },
        goto: function (MenuData) {
            if (MenuData.Type == 1) {
                this.gotoCategoryByCId(MenuData.CId);
            } else if (MenuData.Type == 2) {
                this.gotoPostByPId(MenuData.PId);
            } else if (MenuData.Type == 3) {
                this.gotoUrl(MenuData.Url, MenuData.Blank);
            }
        }
    },
};
</script>