<template>
    <div>
        <Head />
        <Floating />
        <div class="wrapper education login_test_question login_test_done" v-if="ExamData">
            <div class="main_bg">
                <nav class="breadcrumb_box">
                    <div class="container">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
                            <li class=" breadcrumb-item active" aria-current="page">寵物業資格考試系統</li>
                        </ol>
                    </div>
                </nav>
                <section class="main" v-if="!IsFinish">
                    <div class="container">
                        <div class="title">
                            <h1>寵物業資格考試</h1>
                        </div>
                        <div class="bg">
                            <div class="box">
                                <div class="test">
                                    <h2>是非題</h2>
                                    <div v-for="(Exam, index) in ExamData.ExamA" :key="index">
                                        <hr v-if="index > 0">
                                        <div class="form-group">
                                            <label for="">{{ index + 1 }}.{{ Exam.QuestionContent }}</label>
                                            <div>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" :name="'Q' + Exam.EId"
                                                        :id="'Q' + Exam.EId + '_Y'" value="對">
                                                    <label class="form-check-label" :for="'Q' + Exam.EId + '_Y'">
                                                        對
                                                    </label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="radio" :name="'Q' + Exam.EId"
                                                        :id="'Q' + Exam.EId + '_N'" value="錯">
                                                    <label class="form-check-label" :for="'Q' + Exam.EId + '_N'">
                                                        錯
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="test choose">
                                    <h2>選擇題</h2>
                                    <div v-for="(Exam, index) in ExamData.ExamB" :key="index">
                                        <hr v-if="index > 0">
                                        <div class="form-group">
                                            <label for="">{{ index + 1 }}.{{ Exam.QuestionContent }}</label>
                                            <div>
                                                <div class="form-check form-check-inline" v-if="Exam.AnswerA">
                                                    <input class="form-check-input" type="radio" :name="'Q' + Exam.EId"
                                                        :id="'Q' + Exam.EId + '_A'" value="A">
                                                    <label class="form-check-label" :for="'Q' + Exam.EId + '_A'">
                                                        <span>A</span>{{ Exam.AnswerA }}
                                                    </label>
                                                </div>
                                                <div class="form-check form-check-inline" v-if="Exam.AnswerB">
                                                    <input class="form-check-input" type="radio" :name="'Q' + Exam.EId"
                                                        :id="'Q' + Exam.EId + '_B'" value="B">
                                                    <label class="form-check-label" :for="'Q' + Exam.EId + '_B'">
                                                        <span>B</span>{{ Exam.AnswerB }}
                                                    </label>
                                                </div>
                                                <div class="form-check form-check-inline" v-if="Exam.AnswerC">
                                                    <input class="form-check-input" type="radio" :name="'Q' + Exam.EId"
                                                        :id="'Q' + Exam.EId + '_C'" value="C">
                                                    <label class="form-check-label" :for="'Q' + Exam.EId + '_C'">
                                                        <span>C</span>{{ Exam.AnswerC }}
                                                    </label>
                                                </div>
                                                <div class="form-check form-check-inline" v-if="Exam.AnswerD">
                                                    <input class="form-check-input" type="radio" :name="'Q' + Exam.EId"
                                                        :id="'Q' + Exam.EId + '_D'" value="D">
                                                    <label class="form-check-label" :for="'Q' + Exam.EId + '_D'">
                                                        <span>D</span>{{ Exam.AnswerD }}
                                                    </label>
                                                </div>
                                                <div class="form-check form-check-inline" v-if="Exam.AnswerE">
                                                    <input class="form-check-input" type="radio" :name="'Q' + Exam.EId"
                                                        :id="'Q' + Exam.EId + '_E'" value="E">
                                                    <label class="form-check-label" :for="'Q' + Exam.EId + '_E'">
                                                        <span>E</span>{{ Exam.AnswerE }}
                                                    </label>
                                                </div>
                                                <div class="form-check form-check-inline" v-if="Exam.AnswerF">
                                                    <input class="form-check-input" type="radio" :name="'Q' + Exam.EId"
                                                        :id="'Q' + Exam.EId + '_F'" value="F">
                                                    <label class="form-check-label" :for="'Q' + Exam.EId + '_F'">
                                                        <span>F</span>{{ Exam.AnswerF }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="btn_box">
                                    <button @click="sendAnswer()">確認送出</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="main" v-if="IsFinish">
                    <div class="container">
                        <div class="title">
                            <h1>寵物業資格考試系統</h1>
                        </div>
                        <div class="bg" v-if="!Passing">
                            <div class="box">
                                <h2>寵物業資格考試</h2>
                                <div class="done">
                                    <div class="aa">
                                        <div class="fail">
                                            <div class="line"></div>
                                            <div class="line"></div>
                                            <!-- <div class="circle"></div> -->
                                        </div>
                                        <p>不合格</p>
                                    </div>
                                    <img src="/img/line_icon.svg" alt="">
                                </div>
                                <div class="text">
                                    <p>
                                        需兩星期後才能重新報考
                                    </p>
                                </div>
                                <div class="btn_box">
                                    <router-link tag="button" :to="{ name: 'ExamList' }">查看歷史紀錄</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="bg" v-if="Passing">
                            <div class="box">
                                <h2>寵物業資格考試</h2>
                                <div class="done">
                                    <div class="aa">
                                        <div class="fail">
                                            <div class="circle"></div>
                                        </div>
                                        <p>合　格</p>
                                    </div>
                                    <img src="/img/line_icon.svg" alt="">
                                </div>
                                <div class="btn_box">
                                    <router-link tag="button" :to="{ name: 'ExamList' }">查看歷史紀錄</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <FooterMenu />
        <Footer />
    </div>
</template>

<script>
import Head from "@/components/Head.vue";
import Floating from "@/components/Floating.vue";
import FooterMenu from "@/components/FooterMenu.vue";
import Footer from "@/components/Footer.vue";
import Validate from "@/utils/validate.js";
import VueCookies from "vue-cookies";
import CountDown from "@/utils/countdown.js";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

export default {
    name: 'ExamPage',
    components: {
        Head,
        Floating,
        FooterMenu,
        Footer,
    },
    data() {
        return {
            ExamData: null,
            LastTime: 900,
            IsFinish: false,
            Passing: false,
        };
    },
    created: function () {
        Vue.axios
            .get(
                this.$store.state.src +
                this.$store.state.subDirectory +
                "/Html/ExamPage",
                {
                    params: { token: $cookies.get("aow_login_token") },
                }
            )
            .then((response) => {
                this.ExamData = response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    },
    updated: function () { },
    mounted: function () {
        let that = this;
        var loop = window.setInterval(function () {
            that.LastTime -= 1;
            if (that.LastTime <= 0) {
                // 時間到
                window.clearInterval(loop);
                this.sendAnswer();
            }
        }, 1000);
    },
    methods: {
        sendAnswer() {
            var Answers = new Array();
            // 是非題
            $.each(this.ExamData.ExamA, function (index, Exam) {
                var jObj = new Object;
                jObj.EId = Exam.EId;
                jObj.Answer = $('input[name=Q' + Exam.EId + ']:checked').val();
                if (!jObj.Answer) {
                    jObj.Answer = '';
                }
                Answers = Answers.concat(jObj);
            });
            // 選擇題
            $.each(this.ExamData.ExamB, function (index, Exam) {
                var jObj = new Object;
                jObj.EId = Exam.EId;
                jObj.Answer = $('input[name=Q' + Exam.EId + ']:checked').val();
                if (!jObj.Answer) {
                    jObj.Answer = '';
                }
                Answers = Answers.concat(jObj);
            });
            // 顯示請稍候
            Swal.fire({
                title: "請稍候...",
                allowOutsideClick: false,
                showConfirmButton: false,
                heightAuto: false,
                willOpen: function () {
                    Swal.showLoading();
                },
            });
            let that = this;
            $.ajax({
                url:
                    that.$store.state.src +
                    that.$store.state.subDirectory +
                    "/Html/ExamPage",
                type: "post",
                data: {
                    token: $cookies.get("aow_login_token"),
                    ExamA: JSON.stringify(that.ExamData.ExamA),
                    ExamB: JSON.stringify(that.ExamData.ExamB),
                    Answers: JSON.stringify(Answers),
                    StartTime: that.ExamData.StartTime,
                    LastTime: that.LastTime,
                },
                dataType: "json",
                error: function () { },
                success: function (res) {
                    // 判斷結果
                    if (res.ok) {
                        that.Passing = res.Passing;
                        Swal.fire({
                            title: "送出完成",
                            icon: "success",
                            allowOutsideClick: false,
                            showConfirmButton: false,
                            heightAuto: false,
                            timer: 1500,
                            willClose: () => {
                                that.IsFinish = true;
                            },
                        });
                    } else if (res.error) {
                        Swal.fire({
                            title: "錯誤",
                            text: res.error,
                            icon: "error",
                            allowOutsideClick: false,
                            confirmButtonText: "確定",
                            heightAuto: false,
                        });
                    }
                },
            });
        }
    },
}
</script>