import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Category from '../views/Category.vue'
import Post from '../views/Post.vue'
import Download from '../views/Download.vue'
import SiteMap from '../views/SiteMap.vue'
import ActivityList from '../views/ActivityList.vue'
import Activity from '../views/Activity.vue'
import BurialActivity from '../views/BurialActivity.vue'
import BurialActivityBooking from '../views/BurialActivityBooking.vue'
import BurialActivitySearch from '../views/BurialActivitySearch.vue'
import ExamLogin from '../views/ExamLogin.vue'
import ExamStart from '../views/ExamStart.vue'
import ExamList from '../views/ExamList.vue'
import ExamPage from '../views/ExamPage.vue'
import Search from '../views/Search.vue'
import LifeEduExam from '../views/LifeEduExam.vue'
import PetCertificationCard from '../views/PetCertificationCard.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Category/:CId',
    name: 'Category',
    component: Category
  },
  {
    path: '/Post/:PId/:CId?',
    name: 'Post',
    component: Post
  },
  {
    path: '/Download',
    name: 'Download',
    component: Download
  },
  {
    path: '/SiteMap',
    name: 'SiteMap',
    component: SiteMap
  },
  {
    path: '/ActivityList',
    name: 'ActivityList',
    component: ActivityList
  },
  {
    path: '/Activity/:AId',
    name: 'Activity',
    component: Activity
  },
  {
    path: '/BurialActivity',
    name: 'BurialActivity',
    component: BurialActivity
  },
  {
    path: '/BurialActivityBooking',
    name: 'BurialActivityBooking',
    component: BurialActivityBooking
  },
  {
    path: '/BurialActivitySearch',
    name: 'BurialActivitySearch',
    component: BurialActivitySearch
  },
  {
    path: '/ExamLogin',
    name: 'ExamLogin',
    component: ExamLogin
  },
  {
    path: '/ExamStart',
    name: 'ExamStart',
    component: ExamStart
  },
  {
    path: '/ExamList',
    name: 'ExamList',
    component: ExamList
  },
  {
    path: '/ExamPage',
    name: 'ExamPage',
    component: ExamPage
  },
  {
    path: '/Search/:Keyword',
    name: 'Search',
    component: Search
  },
  {
    path: '/LifeEduExam',
    name: 'LifeEduExam',
    component: LifeEduExam
  },
  {
    path: '/PetCertificationCard/:token',
    name: 'PetCertificationCard',
    component: PetCertificationCard
  },
]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

export default router
