<template>
    <div>
        <Head />
        <Floating />
        <div class="wrapper sitemap">
            <!-- 背景 -->
            <div class="main_bg">
                <!-- 麵包屑 -->
                <nav class="breadcrumb_box">
                    <div class="container">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">網站導覽</li>
                        </ol>
                    </div>
                </nav>
                <!-- 網站導覽 -->
                <section class="main">
                    <div class="container">
                        <div class="title">
                            <h1>網站導覽</h1>
                        </div>
                        <div class="bg">
                            <div class="box">
                                <div class="text">
                                    <h2>無障礙導覽說明</h2>
                                    <p>
                                        本網站設計符合無障礙的標準，並提供便捷鍵（Access Key）的設定，以幫助障礙人士使用。
                                        <br>
                                        本網站依無障礙網頁設計原則而建置，分別依網頁佈局方向來設置便捷鍵，並且該便捷鍵均可超連結至此頁完成方便瀏覽本網站的主要功能。
                                    </p>
                                </div>
                                <hr>
                                <ul class="list" v-if="SiteMapData" v-html="SiteMapData.Content"></ul>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <FooterMenu />
        <Footer />
    </div>
</template>

<script>
import Head from "@/components/Head.vue";
import Floating from "@/components/Floating.vue";
import FooterMenu from "@/components/FooterMenu.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

export default {
    name: 'SiteMap',
    components: {
        Head,
        Floating,
        FooterMenu,
        Footer,
    },
    data() {
        return {
            SiteMapData: null,
        };
    },
    created: function () {
        Vue.axios
            .get(
                this.$store.state.src +
                this.$store.state.subDirectory +
                "/Html/SiteMap",
                {
                    params: {
                        lang: $cookies.get("aow_site_lang")
                    },
                }
            )
            .then((response) => {
                this.SiteMapData = response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    },
    mounted: function () { },
    methods: {},
}
</script>