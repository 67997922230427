<template>
    <div>
        <Head />
        <Floating />
        <div class="wrapper download_list" v-if="DownloadData">
            <div class="main_bg">
                <nav class="breadcrumb_box">
                    <div class="container">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link to="/">首頁</router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">下載專區
                            </li>
                        </ol>
                    </div>
                </nav>
                <section class="news">
                    <div class="container">
                        <div class="title">
                            <h1>下載專區</h1>
                        </div>
                        <div class="bg">
                            <div class="search_box">
                                <div class="form-group ">
                                    <select class="form-control" v-model="Type">
                                        <option v-for="(_Type, index) in DownloadData.Types" :key="index" :value="index == 0 ? '' : _Type">{{ _Type }}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" aria-describedby="" placeholder="請輸入關鍵字"
                                        v-model="Keyword">
                                </div>
                                <button class="" @click="loadList(1)">搜尋</button>
                            </div>
                            <ul class="list show">
                                <table>
                                    <thead>
                                        <tr>
                                            <th class="basis_100">類別</th>
                                            <th>檔案名稱</th>
                                            <th class="basis_100">發佈時間</th>
                                            <th class="basis_100">發布單位</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="OfficalWebDownload in OfficalWebDownloads" :key="OfficalWebDownload.DId">
                                            <td data-th="類別" class="basis_100">{{ OfficalWebDownload.Type }}</td>
                                            <td data-th="檔案名稱"><a :href="OfficalWebDownload.Url" target="_blank">{{
                                                OfficalWebDownload.FileName }}</a></td>
                                            <td data-th="發佈時間" class="basis_100">{{ OfficalWebDownload.CreateTime |
                                                timeString("YYYY-MM-DD") }}</td>
                                            <td data-th="發布單位" class="basis_100">{{ OfficalWebDownload.Unit }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </ul>
                        </div>
                        <paginate :no-li-surround="false" :page-count="pageAll" :page-range="3" :margin-pages="1"
                            :click-handler="loadList" :prev-text="'«'" :next-text="'»'" :prev-link-class="'page-link'"
                            :next-link-class="'page-link'" :container-class="'pagination'" :page-class="'page-item'"
                            :page-link-class="'page-link'" :active-class="'active'" :hide-prev-next="false"
                            v-if="pageAll > 1">
                        </paginate>
                    </div>
                </section>
            </div>
        </div>
        <FooterMenu />
        <Footer />
    </div>
</template>

<script>
import Head from "@/components/Head.vue";
import Floating from "@/components/Floating.vue";
import FooterMenu from "@/components/FooterMenu.vue";
import Footer from "@/components/Footer.vue";

import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

import Paginate from "vuejs-paginate";

Vue.component("paginate", Paginate);

export default {
    name: 'Download',
    components: {
        Head,
        Floating,
        FooterMenu,
        Footer,
    },
    data() {
        return {
            DownloadData: null,
            OfficalWebDownloads: null,
            pageAll: 0,
            perPage: 10,
            Type: '',
            Keyword: '',
        };
    },
    created: function () {
        Vue.axios
            .get(
                this.$store.state.src +
                this.$store.state.subDirectory +
                "/Html/DownloadData",
                {
                    params: {},
                }
            )
            .then((response) => {
                this.DownloadData = response.data;
                this.loadList(1);
            })
            .catch((error) => {
                console.log(error);
            });
    },
    mounted: function () { },
    methods: {
        loadList: function (page) {
            var that = this;
            $.ajax({
                url:
                    this.$store.state.src +
                    this.$store.state.subDirectory +
                    "/Html/DownloadList",
                type: "post",
                data: {
                    page: page,
                    perPage: that.perPage,
                    type: that.Type,
                    keyword: that.Keyword,
                },
                dataType: "json",
                error: function () { },
                success: function (res) {
                    if (res.status == 1) {
                        that.OfficalWebDownloads = res.OfficalWebDownloads;
                        that.perPage = res.perPage;
                        that.pageAll = Math.max(Math.ceil(res.total / that.perPage), 1);
                        document.body.scrollTop = 0;
                    }
                },
            });
        },
    },
}
</script>